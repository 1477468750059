( function ( $ ) {

	'use strict';

	const pluginName = 'liquidMobileNav';

	let defaults = {
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.element = element;
			this.$element = $( element );
			this.$mobileSec = $( '.lqd-mobile-sec', $liquidMainHeader );
			this.$mobileSecInner = $( '.lqd-mobile-sec-inner', this.$mobileSec );
			this.$mobileNavCollapse = $( '.mobile-navbar-collapse', this.$mobileSec );

			this.$navItems = [];

			this.init();

		}

		init() {

			this.removeStyleTags();
			this.addHeightVar();
			this.mobileModules();

			/*
				adding .elementor for styling
				can't add the classname in markup because it will conflict with elementor pro and elementor cloud
			*/
			if ( 'elementorFrontend' in window && !elementorFrontend.isEditMode() ) {
				this.$mobileSec.addClass( 'elementor' );
			}

		}

		removeStyleTags() {

			fastdom.mutate( () => {

				this.$mobileSec.find( '.navbar-collapse style[data-type=vc_shortcodes-custom-css]' ).remove();

			} );


		}

		addHeightVar() {

			fastdom.mutate( () => {

				if ( this.$mobileSecInner.length ) {

					document.documentElement.style.setProperty( '--lqd-mobile-sec-height', `${ this.$mobileSecInner[ 0 ].offsetHeight }px` );

				}

			} );

		}

		mobileModules() {

			const $mobileModules = $( '.lqd-show-on-mobile', this.element );

			if ( !$mobileModules.length ) return;

			const $mobileModulesContainer = $( '.lqd-mobile-modules-container', this.$mobileSec );


			fastdom.mutate( () => {

				$mobileModulesContainer.removeClass( 'empty' );

				$mobileModules.each( ( i, mobileModule ) => {

					const $module = $( mobileModule );

					if ( !$module.children().length ) return false;

					const $clonedModule = $module.clone( true );
					const $triggerElement = $( '[data-target]', $clonedModule );

					if ( $triggerElement.length ) {

						const target = $triggerElement.attr( 'data-target' );
						const $targetEelement = $( target, $clonedModule );

						$targetEelement.attr( {
							'id': `${ target.replace( '#', '' ) }-cloned`
						} );

						$triggerElement.attr( {
							'data-target': `${ target }-cloned`,
							'aria-controls': `${ target.replace( '#', '' ) }-cloned`
						} );

						$targetEelement.on( 'show.bs.collapse', () => {
							$targetEelement.add( $triggerElement ).addClass( 'is-active' )
						} )
						$targetEelement.on( 'hide.bs.collapse', () => {
							$targetEelement.add( $triggerElement ).removeClass( 'is-active' )
						} )

					}

					$clonedModule.appendTo( $mobileModulesContainer );

					if ( !$clonedModule.hasClass( 'header-module' ) ) {
						$clonedModule.wrap( '<div class="header-module" />' )
					}

				} );

			} );

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'mobilenav-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function () {

	if ( document.body.hasAttribute( 'data-mobile-header-builder' ) ) return;

	$liquidMainHeader.liquidMobileNav();

} );