(function ( $ ) {
	
	'use strict';
		
	const pluginName = 'liquidAccordion';
	let defaults = {
	};
	
	function Plugin( element, options ) {
		
		this.element = element;
		this.$element = $(element);
		
		this.options = $.extend( {}, defaults, options) ;
		
		this._defaults = defaults;
		this._name = pluginName;
		
		this.init();
	}
	
	Plugin.prototype = {
		
		init: function() {
			
			this.setHashOnLoad();
			this.eventHandlers();
			
		},
		
		setHashOnLoad: function() {
			
			const element = $(this.element);
			
			if ( location.hash !== '' && element.find(location.hash).length ) {

				const activeItemParent = element.find(location.hash).closest('.accordion-item');

				// can't use BS .collapse(). it's accordion loosing functionality
				activeItemParent.find(location.hash).addClass('in');
				activeItemParent.find('.accordion-heading').find('a').attr('aria-expanded', 'true').removeClass('collapsed');
				
				activeItemParent.siblings().find('.in').removeClass('in');
				activeItemParent.siblings().find('.accordion-heading').find('a').attr('aria-expanded', 'false').addClass('collapsed');
				
			}
			
		},
		
		eventHandlers: function() {
			
			this.$element.find('.accordion-collapse').on('show.bs.collapse', (event) => {
				this.onShow.call(this, event);
			});
			
			this.$element.find('.accordion-collapse').on('shown.bs.collapse', (event) => {
				this.onShown.call(this, event);
			});
			
			this.$element.find('.accordion-collapse').on('hide.bs.collapse', (event) => {
				this.onHide.call(this, event);
			});
			
		},
		
		onShow: function(event) {
			
			this.toggleActiveClass(event, 'show');
			this.setHashOnLoad(event);

			const $collapse = $(event.target);
			if ( $collapse.closest('.vc_vc_accordion_tab').length ) {
				$collapse.closest('.vc_vc_accordion_tab').siblings().find('.accordion-collapse').collapse('hide');
			}
			
		},
		
		onHide(event) {

			this.toggleActiveClass(event, 'hide');
			
		},

		toggleActiveClass(event, state) {

			const parent = $(event.target).closest('.accordion-item');

			if ( state === 'show' ) {
				parent.addClass('active').siblings().removeClass('active');
			}

			if ( state === 'hide' ) {
				parent.removeClass('active');
			}

		},
		
		setHashOnShow(event) {
			
			if( history.pushState ) {
				history.pushState(null, null, '#'+$(event.target).attr('id'));
			} else {
				location.hash = '#'+$(event.target).attr('id');
			}
		},
		
		onShown: function(event) {
			
			const collapse = $(event.target);
			const $parent = collapse.closest('.accordion-item');
			const $window = $(window);
			const parentOffsetTop = $parent.offset().top;

			this.initPlugins($parent);
			
			if ( parentOffsetTop <= $window.scrollTop() - 15 ) {
				
				$('html, body').animate({
					scrollTop: parentOffsetTop - 45
				}, 800);
				
			}
			
		},

		initPlugins($shownAccordionItem) {

			$('[data-split-text]', $shownAccordionItem).liquidSplitText();

		},

		destroy() {

			this.$element.find('.accordion-collapse').off('show.bs.collapse shown.bs.collapse hide.bs.collapse');

		}
		
	};
	
	$.fn[ pluginName ] = function( options ) {
		
		return this.each( function() {
			
			const pluginOptions = {...$(this).data('accordion-options'), ...options};
			
			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}
			
		} );
		
	};
	
}(jQuery));

jQuery(document).ready( function($) {
	$('.accordion').liquidAccordion();
});