/* eslint-disable no-redeclare, no-unused-vars */

window.liquidPageLoaded = false;

window.liquidIsElementor = window.liquidIsElementor || document.body.classList.contains( 'elementor-page' );

window.liquidElements = ( $ ) => {

	window.$liquidWindow = $( window );
	window.$liquidHtml = $( 'html' );
	window.$liquidBody = $( 'body' );
	window.$liquidSiteWrap = $( '#wrap' );
	window.$liquidContents = $( '#lqd-site-content' );
	window.$liquidContentsWrap = $( '#lqd-contents-wrap' );
	window.$liquidMainHeader = $( '.main-header' );
	window.$liquidMainFooter = $( '.main-footer' );

	window.$liquidSectionsWrapper = $liquidContentsWrap;

	const isPfSinglePage = $liquidBody.hasClass( 'single-liquid-portfolio' );
	const isBlogSinglePage = $liquidBody.hasClass( 'lqd-blog-post' );

	if ( liquidIsElementor ) {
		const $secWrap = $( '.elementor-section-wrap', $liquidContentsWrap ).first();
		window.$liquidSectionsWrapper = $secWrap.legth ? $secWrap : $( '> .elementor', $liquidContentsWrap ).first();
	}
	if ( isPfSinglePage ) {
		window.$liquidSectionsWrapper = $( '.pf-single-contents' );
		const $elementorWrapper = window.$liquidSectionsWrapper.children( '.elementor' );
		if ( $elementorWrapper.length ) {
			window.$liquidSectionsWrapper = $elementorWrapper;
		}
	}
	if ( isBlogSinglePage ) {
		window.$liquidSectionsWrapper = $( '.lqd-single-post-content > .container' );
		const $elementorWrapper = window.$liquidSectionsWrapper.children( '.elementor' );
		if ( $elementorWrapper.length ) {
			window.$liquidSectionsWrapper = $elementorWrapper;
		}
	}

	const elementorSectionsSelector = `
	> .elementor-section-wrap > .elementor-section,
	> .elementor-section,
	> .e-con,
	> .e-con > .e-con,
	> .e-con > .e-con-inner > .e-con,
	> .e-container,
	> .e-container > .e-container,
	> .elementor-section-wrap > .elementor-top-section > .elementor-container > .elementor-column > .elementor-widget-wrap > .elementor-inner-section,
	> .elementor-top-section > .elementor-container > .elementor-column > .elementor-widget-wrap > .elementor-inner-section`;
	const $elementorFooterSections = $( '> .elementor', $liquidMainFooter ).find( elementorSectionsSelector );

	window.$liquidSections =
		liquidIsElementor ?
			$liquidSectionsWrapper.find( elementorSectionsSelector ).add( $elementorFooterSections ) :
			$liquidSectionsWrapper.add( $liquidMainFooter ).find( '.lqd-section, > .vc_row, > .vc_section, > .vc_section > .vc_row, > .lqd-section-scroll-sections > .vc_row, > .vc_element' );

	if ( ( isPfSinglePage || isBlogSinglePage ) && liquidIsElementor ) {
		window.$liquidSections = window.$liquidSections.add( window.$liquidSectionsWrapper.find( '> .elementor' ).find( elementorSectionsSelector ) );
	}
	if ( isBlogSinglePage && liquidIsElementor ) {
		window.$liquidSections = $( window.$liquidSections.get() ).add( '.lqd-post-cover' );
		/**
		 * in case if there's .container right inside .lqd-single-post-content and it doesn't allow sections to stretch to full width
		 */
		if ( $( '.lqd-single-post-content > .container' ).length ) {
			window.$liquidSections = $( window.$liquidSections.get() ).add( window.$liquidContents );
		}
	}

	/**
	 * if there's still no sections available, then it's probably the html version
	 */
	if ( !window.$liquidSections.length ) {
		window.$liquidSections = $liquidSectionsWrapper.find( '> section' ).add( window.$liquidMainFooter?.find( '> section' ) );
	}

	window.liquidBodyBg = window.$liquidBody.css( 'backgroundColor' );
	window.liquidContentsBg = window.$liquidContents.css( 'backgroundColor' );
	window.liquidMainFooterBg = window.$liquidMainFooter.css( 'backgroundColor' );

};

liquidElements( jQuery );

window.liquidHeaderIsElementor = $liquidMainHeader.children( '.elementor:not(.lqd-mobile-sec)' ).length;
window.liquidLazyloadEnabled = $liquidBody.hasClass( 'lazyload-enabled' );
window.liquidCheckedFonts = [];

window.liquidIsMobile = function () {
	return (
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test( navigator.userAgent ) ||
		( navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0 ) ||
		navigator.platform === 'iPad'
	)
};

if ( liquidIsMobile() ) {
	document.documentElement.classList.add( 'vc_mobile' );
	document.body.setAttribute( 'data-elementor-device-mode', 'mobile' );
};

window.liquidMobileNavBreakpoint = function () {
	if ( window.liquidParams && window.liquidParams.mobileNavBreakpoint ) {
		return window.liquidParams.mobileNavBreakpoint;
	} else {
		return $liquidBody.data( 'mobile-nav-breakpoint' ) || 1199;
	}
}

window.liquidWindowWidth = function () {
	return window.innerWidth;
}
window.liquidWindowHeight = function () {
	return window.innerHeight;
};
window.liquidDocHeight = function () {
	return document.body.clientHeight;
};

/**
Some functions from Underscore js https://underscorejs.org/
*/

// Some functions take a variable number of arguments, or a few expected
// arguments at the beginning and then a variable number of values to operate
// on. This helper accumulates all remaining arguments past the function’s
// argument length (or an explicit `startIndex`), into an array that becomes
// the last argument. Similar to ES6’s "rest parameter".
const restArguments = function ( func, startIndex ) {
	startIndex = startIndex == null ? func.length - 1 : +startIndex;
	return function () {
		var length = Math.max( arguments.length - startIndex, 0 ),
			rest = Array( length ),
			index = 0;
		for ( ; index < length; index++ ) {
			rest[ index ] = arguments[ index + startIndex ];
		}
		switch ( startIndex ) {
			case 0: return func.call( this, rest );
			case 1: return func.call( this, arguments[ 0 ], rest );
			case 2: return func.call( this, arguments[ 0 ], arguments[ 1 ], rest );
		}
		var args = Array( startIndex + 1 );
		for ( index = 0; index < startIndex; index++ ) {
			args[ index ] = arguments[ index ];
		}
		args[ startIndex ] = rest;
		return func.apply( this, args );
	};
};

// Delays a function for the given number of milliseconds, and then calls
// it with the arguments supplied.
const liquidDelay = restArguments( function ( func, wait, args ) {
	return setTimeout( function () {
		return func.apply( null, args );
	}, wait );
} );

// A (possibly faster) way to get the current timestamp as an integer.
const liquidNow = Date.now || function () {
	return new Date().getTime();
};

// Returns a function, that, when invoked, will only be triggered at most once
// during a given window of time. Normally, the throttled function will run
// as much as it can, without ever going more than once per `wait` duration;
// but if you'd like to disable the execution on the leading edge, pass
// `{leading: false}`. To disable execution on the trailing edge, ditto.
window.liquidThrottle = function ( func, wait, options ) {
	var timeout, context, args, result;
	var previous = 0;
	if ( !options ) options = {};

	var later = function () {
		previous = options.leading === false ? 0 : liquidNow();
		timeout = null;
		result = func.apply( context, args );
		if ( !timeout ) context = args = null;
	};

	var throttled = function () {
		var now = liquidNow();
		if ( !previous && options.leading === false ) previous = now;
		var remaining = wait - ( now - previous );
		context = this;
		args = arguments;
		if ( remaining <= 0 || remaining > wait ) {
			if ( timeout ) {
				clearTimeout( timeout );
				timeout = null;
			}
			previous = now;
			result = func.apply( context, args );
			if ( !timeout ) context = args = null;
		} else if ( !timeout && options.trailing !== false ) {
			timeout = setTimeout( later, remaining );
		}
		return result;
	};

	throttled.cancel = function () {
		clearTimeout( timeout );
		previous = 0;
		timeout = context = args = null;
	};

	return throttled;
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
window.liquidDebounce = function ( func, wait, immediate ) {
	var timeout, result;

	var later = function ( context, args ) {
		timeout = null;
		if ( args ) result = func.apply( context, args );
	};

	var debounced = restArguments( function ( args ) {
		if ( timeout ) clearTimeout( timeout );
		if ( immediate ) {
			var callNow = !timeout;
			timeout = setTimeout( later, wait );
			if ( callNow ) result = func.apply( this, args );
		} else {
			timeout = liquidDelay( later, wait, this, args );
		}

		return result;
	} );

	debounced.cancel = function () {
		clearTimeout( timeout );
		timeout = null;
	};

	return debounced;
};

window.liquidGetMousePos = ( ev, basedOnElement ) => {
	let posx = 0;
	let posy = 0;
	if ( !ev ) ev = window.event;
	if ( ev.pageX || ev.pageY ) {
		posx = ev.pageX;
		posy = ev.pageY;
	}
	else if ( ev.clientX || ev.clientY ) {
		posx = ev.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
		posy = ev.clientY + document.body.scrollTop + document.documentElement.scrollTop;
	}
	if ( basedOnElement ) {
		const rect = ev.currentTarget.getBoundingClientRect();
		posx = posx - rect.left - window.scrollX;
		posy = posy - rect.top - window.scrollY;
	}
	return { x: posx, y: posy };
}