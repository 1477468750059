( function ( $ ) {

	'use strict';

	const headerStickyOptions = $liquidMainHeader.length && $liquidMainHeader[ 0 ].getAttribute( 'data-sticky-options' );
	const headerIsDynamicColor = headerStickyOptions && JSON.parse( headerStickyOptions ).dynamicColors === true;

	const pluginName = 'liquidStack';
	let defaults = {
		sectionSelector: '#lqd-contents-wrap > .vc_row, #lqd-contents-wrap > .vc_section', // outer rows only
		anchors: [],
		easing: 'linear',
		scrollingSpeed: 1200,
		loopTop: false,
		loopBottom: false,
		navigation: false,
		defaultTooltip: 'Section',
		prevNextButtons: true,
		prevNextLabels: { prev: 'Previous', next: 'Next' },
		pageNumber: true,
		effect: 'none', // [  'none', 'fadeScale', 'slideOver' ]
		disableOnMobile: true,
		normalScrollElements: null,
		normalScrollElementTouchThreshold: 5,
		touchSensitivity: 5,
	};

	const $backToTopButton = $( '[data-back-to-top], [data-lqd-scroll-indicator]' );

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.element = element;
			this.$element = $( element );

			if (
				this.options.disableOnMobile &&
				( liquidIsMobile() || liquidWindowWidth() <= liquidMobileNavBreakpoint() )
			) return false;

			this.lastScrolledDestiny;
			this.lastAnimation = 0;
			this.scrollings = [];
			this.isTouch = ( ( 'ontouchstart' in window ) || ( navigator.msMaxTouchPoints > 0 ) || ( navigator.maxTouchPoints ) );
			this.touchStartY = 0;
			this.touchEndY = 0;
			this.prevTime = new Date().getTime();

			this.scrollDelay = 600;

			this.anchors = [];
			this.tooltips = [];

			this.$sectionElements = null;
			this.$ppNav = null;
			this.$ppNavCurrent = null;
			this.$prevNextButtons = $( '.lqd-stack-prevnext-wrap' );
			this.$pageNumber = $( '.lqd-stack-page-number' );
			this.$stickyModules = liquidIsElementor ?
				$liquidMainHeader.find( '> .elementor > .elementor-section-wrap > .elementor-section, > .elementor > .elementor-section, > .elementor > .e-container, > .elementor > .e-con' ).not( '.lqd-hide-onstuck' ).find( '[data-element_type="widget"]' ) :
				$liquidMainHeader.find( '.lqd-head-sec-wrap, .lqd-stickybar-wrap' ).not( '.lqd-hide-onstuck' ).find( '.lqd-head-col > .header-module' );
			this.$pageNumbersStyle = $liquidBody.hasClass( 'lqd-stack-nums-style-1' ) ? 'style1' : $liquidBody.hasClass( 'lqd-stack-nums-style-2' ) ? 'style2' : '';

			this.stackInitPromise = new Promise( resolve => {
				this.$element.on( 'stackinit', resolve.bind( this, this ) )
			} );

			this.$mainNavLocalScroll = $( '.main-nav[data-localscroll]' );

			LiquidSectionsDetails.getDetails().then( sections => {

				const contentSections = sections.filter( sec => !sec.isInFooter && !sec.isInnerSection );
				const secs = contentSections.map( sec => sec.el );

				this.$sectionElements = $( secs );

				this.build();
				this.addClassnames();
				this.eachSection();
				this.init();

				this.$element.trigger( 'stackinit' );

			} );

		}

		/**
		* Moves sectio up
		*/
		moveSectionUp() {
			var prev = this.getActiveSection().$element.prev( '.pp-section' );

			//looping to the bottom if there's no more sections above
			if ( !prev.length && this.options.loopTop ) {
				prev = this.$sectionElements.last();
			}

			if ( prev.length ) {
				this.scrollPage( prev );
			}
		}

		/**
		* Moves sectio down
		*/
		moveSectionDown() {
			var next = this.getActiveSection().$element.next( '.pp-section' );

			//looping to the top if there's no more sections below
			if ( !next.length && this.options.loopBottom ) {
				next = this.$sectionElements.first();
			}

			if ( next.length ) {
				this.scrollPage( next );
			}
		}

		/**
		* Moves the site to the given anchor or index
		*/
		moveTo( section ) {

			let sec;

			if ( isNaN( section ) ) {
				sec = this.$sectionElements.filter( ( i, el ) => el.getAttribute( 'data-anchor' ) === section );
			} else {
				sec = this.$sectionElements.eq( ( section - 1 ) );
			}

			this.scrollPage( sec );
		}

		getActiveSection() {

			const $element = this.$sectionElements.filter( ( i, el ) => $( el ).hasClass( 'active' ) );
			const index = this.$sectionElements.get().findIndex( el => el.classList.contains( 'active' ) );

			return {
				$element,
				index
			}

		}

		makeScrollable( section ) {

			const $section = $( section );
			const $children = $section.children().filter( ( i, child ) => {
				const $childPos = $( child ).css( 'position' );
				return $childPos !== 'absolute' && $childPos !== 'fixed';
			} );
			const sectionHeight = $section.outerHeight();
			let childrenHeight = 0;

			if ( $children.length ) {
				$children.each( ( i, child ) => {
					childrenHeight += $( child ).outerHeight();
				} )
			}

			if ( childrenHeight > sectionHeight ) {

				const sectionPadding = parseInt( $section.css( 'paddingTop' ), 10 ) + parseInt( $section.css( 'paddingBottom' ), 10 );

				$section.addClass( 'pp-scrollable' )

				section.style.setProperty( '--lqd-section-height', `${ childrenHeight + sectionPadding }px` );

			} else {

				$section.removeClass( 'pp-scrollable' )

				section.style.removeProperty( '--lqd-section-height' );

			}

		}

		/**
		* Enables vertical centering by wrapping the content and the use of table and table-cell
		*/
		addTableClass( section ) {

			const $section = $( section );

			$section.addClass( 'd-flex flex-column flex-nowrap align-content-start flex flex-col content-start' );

			if ( !$section.children( '.pp-section-wrap' ).length ) {
				$section.wrapInner( '<div class="pp-section-wrap"><div class="lqd-stack-section-inner"></div></div>' );
			}

		}

		/**
		* Retuns `up` or `down` depending on the scrolling movement to reach its destination
		* from the current section.
		*/
		getYmovement( destiny ) {

			var fromIndex = this.getActiveSection().index;
			var toIndex = this.$sectionElements.index( destiny );
			let dir = 'up';

			if ( fromIndex < toIndex ) {
				dir = 'down';
			}

			return dir;

		}

		/**
		* Scrolls the page to the given destination
		*/
		scrollPage( destination ) {

			const activeSection = this.getActiveSection();

			var v = {
				destination: destination,
				activeSection: activeSection.$element,
				anchorLink: destination.data( 'anchor' ),
				sectionIndex: this.$sectionElements.index( destination ),
				toMove: destination,
				yMovement: this.getYmovement( destination ),
				leavingSection: activeSection.index + 1
			};

			//quiting when activeSection is the target element
			if ( v.activeSection.is( destination ) ) {
				return;
			}

			if ( typeof v.anchorLink !== 'undefined' ) {
				this.setURLHash( v.anchorLink, v.sectionIndex );
			}

			this.$sectionElements.removeClass( 'active' );
			this.setActiveSection( v.sectionIndex );

			v.sectionsToMove = this.getSectionsToMove( v );

			v.translate3d = '';

			//scrolling down (moving sections up making them disappear)
			if ( v.yMovement === 'down' ) {

				if ( v.destination.is( '.pp-auto-height' ) ) {

					var destinationTransform = ( v.destination.outerHeight() * -1 ) + 'px';

					v.translate3d = `translate3d(0px, ${ destinationTransform }, 0px)`;

					v.scrolling = destinationTransform;

					v.sectionsToMove = v.activeSection;

				} else {

					v.scrolling = '-100%';

				}

				v.animateSection = v.activeSection;

			}

			//scrolling up (moving section down to the viewport)
			else {

				v.scrolling = '0';

				v.animateSection = destination;

			}

			this.onLeave( v.leavingSection, ( v.sectionIndex + 1 ), v.yMovement );

			this.performMovement( v );

			this.activateNavDots( v.sectionIndex );
			this.lastScrolledDestiny = v.anchorLink;

			this.lastAnimation = new Date().getTime();
		}

		/**
		* Performs the movement (by CSS3 or by jQuery)
		*/
		performMovement( v ) {

			this.transformContainer( v.animateSection, v.translate3d );

			v.sectionsToMove.each( () => {
				this.transformContainer( $( this ), v.translate3d );
			} );

			setTimeout( () => {
				this.afterSectionLoads( v );
			}, this.options.scrollingSpeed );

		}

		/**
		* Actions to execute after a secion is loaded
		*/
		afterSectionLoads( v ) {
			//callback (afterLoad) if the site is not just resizing and readjusting the slides
			this.afterLoad( v.anchorLink, ( v.sectionIndex + 1 ) );
		}

		getSectionsToMove( v ) {

			var sectionToMove;

			if ( v.yMovement === 'down' ) {
				sectionToMove = this.$sectionElements.map( function ( index ) {
					if ( index < v.destination.index( this.$sectionElements ) ) {
						return $( this );
					}
				} );
			} else {
				sectionToMove = this.$sectionElements.map( function ( index ) {
					if ( index > v.destination.index( this.$sectionElements ) ) {
						return $( this );
					}
				} );
			}

			return sectionToMove;
		}

		/**
		* Sets the URL hash for a section with slides
		*/
		setURLHash( anchorLink ) {
			location.hash = anchorLink;
		}

		//TO DO
		scrollToAnchor() {
			//getting the anchor link in the URL and deleting the `#`
			var value = window.location.hash.replace( '#', '' );
			var sectionAnchor = value;
			var section = this.$sectionElements.filter( ( i, el ) => $( el ).attr( 'data-anchor' ) === sectionAnchor );

			if ( section.length > 0 ) {
				this.scrollPage( section );
			}
		}

		/**
		* Determines if the transitions between sections still taking place.
		* The variable `scrollDelay` adds a "save zone" for devices such as Apple laptops and Apple magic mouses
		*/
		isMoving() {
			var timeNow = new Date().getTime();
			// Cancel scroll if currently animating or within quiet period
			if ( timeNow - this.lastAnimation < this.scrollDelay + this.options.scrollingSpeed ) {
				return true;
			}
			return false;
		}

		/**
		* Actions to do when the hash (#) in the URL changes.
		*/
		hashChangeHandler() {
			var value = window.location.hash.replace( '#', '' ).split( '/' );
			var sectionAnchor = value[ 0 ];

			if ( sectionAnchor.length ) {
				/*in order to call scrollpage() only once for each destination at a time
				It is called twice for each scroll otherwise, as in case of using anchorlinks `hashChange`
				event is fired on every scroll too.*/
				if ( sectionAnchor && sectionAnchor !== this.lastScrolledDestiny ) {
					const section = this.$sectionElements.filter( ( i, el ) => $( el ).attr( 'data-anchor' ) === sectionAnchor );
					this.scrollPage( section );
				}
			}
		}

		/**
		* Cross browser transformations
		*/
		getTransforms( translate3d ) {
			return {
				'-webkit-transform': translate3d,
				'transform': translate3d
			};
		}

		/**
		* Adds a css3 transform property to the container class with or without animation depending on the animated param.
		*/
		transformContainer( element, translate3d ) {
			element.css( this.getTransforms( translate3d ) );
		}

		/**
		* Detecting mousewheel scrolling
		*
		* http://blogs.sitepointstatic.com/examples/tech/mouse-wheel/index.html
		* http://www.sitepoint.com/html5-javascript-mouse-wheel/
		*/
		mouseWheelHandler( e ) {
			var curTime = new Date().getTime();

			// cross-browser wheel delta
			const evt = e.originalEvent;
			var value = evt.wheelDelta || -evt.deltaY || -evt.detail;
			var delta = Math.max( -1, Math.min( 1, value ) );

			var horizontalDetection = typeof evt.wheelDeltaX !== 'undefined' || typeof evt.deltaX !== 'undefined';
			var isScrollingVertically = ( Math.abs( evt.wheelDeltaX ) < Math.abs( evt.wheelDelta ) ) || ( Math.abs( evt.deltaX ) < Math.abs( evt.deltaY ) || !horizontalDetection );

			//Limiting the array to 150 (lets not waste memory!)
			if ( this.scrollings.length > 149 ) {
				this.scrollings.shift();
			}

			//keeping record of the previous scrollings
			this.scrollings.push( Math.abs( value ) );

			//time difference between the last scroll and the current one
			var timeDiff = curTime - this.prevTime;
			this.prevTime = curTime;

			//haven't they scrolled in a while?
			//(enough to be consider a different scrolling action to scroll another section)
			if ( timeDiff > 200 ) {
				//emptying the array, we dont care about old scrollings for our averages
				this.scrollings = [];
			}

			if ( !this.isMoving() ) {
				var activeSection = this.getActiveSection().$element;
				var scrollable = this.isScrollable( activeSection );


				var averageEnd = this.getAverage( this.scrollings, 10 );
				var averageMiddle = this.getAverage( this.scrollings, 70 );
				var isAccelerating = averageEnd >= averageMiddle;

				if ( isAccelerating && isScrollingVertically ) {
					//scrolling down?
					if ( delta < 0 ) {
						this.scrolling( 'down', scrollable );

						//scrolling up?
					} else if ( delta > 0 ) {
						this.scrolling( 'up', scrollable );
					}
				}

				// return false;
			}
		}

		/**
		* Gets the average of the last `number` elements of the given array.
		*/
		getAverage( elements, number ) {
			var sum = 0;

			//taking `number` elements from the end to make the average, if there are not enought, 1
			var lastElements = elements.slice( Math.max( elements.length - number, 1 ) );

			for ( var i = 0; i < lastElements.length; i++ ) {
				sum = sum + lastElements[ i ];
			}

			return Math.ceil( sum / number );
		}

		/**
		* Determines the way of scrolling up or down:
		* by 'automatically' scrolling a section or by using the default and normal scrolling.
		*/
		scrolling( type, scrollable ) {
			var check;
			var scrollSection;

			if ( type == 'down' ) {
				check = 'bottom';
				scrollSection = this.moveSectionDown.bind( this );
			} else {
				check = 'top';
				scrollSection = this.moveSectionUp.bind( this );
			}

			if ( scrollable.length > 0 ) {
				//is the scrollbar at the start/end of the scroll?
				if ( this.isScrolled( check, scrollable ) ) {
					scrollSection();
				} else {
					return true;
				}
			} else {
				//moved up/down
				scrollSection();
			}
		}

		/**
		* Return a boolean depending on whether the scrollable element is at the end or at the start of the scrolling
		* depending on the given type.
		*/
		isScrolled( type, scrollable ) {
			if ( type === 'top' ) {
				return !scrollable.scrollTop();
			} else if ( type === 'bottom' ) {
				return scrollable.scrollTop() + 1 + scrollable.innerHeight() >= scrollable[ 0 ].scrollHeight;
			}
		}

		/**
		* Determines whether the active section or slide is scrollable through and scrolling bar
		*/
		isScrollable( activeSection ) {
			return activeSection.filter( '.pp-scrollable' );
		}

		/**
		* Adds the auto scrolling action for the mouse wheel and tackpad.
		* After this function is called, the mousewheel and trackpad movements will scroll through sections
		*/
		addMouseWheelHandler() {
			$liquidWindow.on( 'mousewheel wheel', this.mouseWheelHandler.bind( this ) );
		}

		/**
	 * Sliding with arrow keys, both, vertical and horizontal
		*/
		handleKeys() {
			$( document ).keydown( ( e ) => {
				if ( !this.isMoving() ) {
					//Moving the main page with the keyboard arrows if keyboard scrolling is enabled
					switch ( e.which ) {
						//up
						case 38:
						case 33:
							this.moveSectionUp();
							break;

						//down
						case 40:
						case 34:
							this.moveSectionDown();
							break;

						//Home
						case 36:
							this.moveTo( 1 );
							break;

						//End
						case 35:
							this.moveTo( $( '.pp-section' ).length );
							break;

						default:
							return; // exit this handler for other keys
					}
				}
			} );
		}

		/**
		* Adds the possibility to auto scroll through sections on touch devices.
		*/
		addTouchHandler() {
			if ( this.isTouch ) {
				this.$element.off( 'touchstart' ).on( 'touchstart', this.touchStartHandler.bind( this ) );
				this.$element.off( 'touchmove' ).on( 'touchmove', this.touchMoveHandler.bind( this ) );
			}
		}

		/**
		* Gets the pageY properties depending on the browser.
		* https://github.com/alvarotrigo/fullPage.js/issues/194#issuecomment-34069854
		*/
		getEventsPage( e ) {
			var events = new Array();

			events.y = ( typeof e.pageY !== 'undefined' && ( e.pageY || e.pageX ) ? e.pageY : e.touches[ 0 ].pageY );

			return events;
		}

		/**
		* As IE >= 10 fires both touch and mouse events when using a mouse in a touchscreen
		* this way we make sure that is really a touch event what IE is detecting.
		*/
		isReallyTouch( e ) {
			//if is not IE   ||  IE is detecting `touch` or `pen`
			return typeof e.pointerType === 'undefined' || e.pointerType != 'mouse';
		}

		/**
		* Getting the starting possitions of the touch event
		*/
		touchStartHandler( event ) {
			var e = event.originalEvent;

			if ( this.isReallyTouch( e ) ) {
				var touchEvents = this.getEventsPage( e );
				this.touchStartY = touchEvents.y;
			}
		}

		/* Detecting touch events
		*/
		touchMoveHandler( event ) {
			var e = event.originalEvent;

			// additional: if one of the normalScrollElements isn't within options.normalScrollElementTouchThreshold hops up the DOM chain
			if ( this.isReallyTouch( e ) ) {

				var activeSection = this.getActiveSection().$element;
				var scrollable = this.isScrollable( activeSection );

				if ( !scrollable.length ) {
					event.preventDefault();
				}

				if ( !this.isMoving() ) {
					var touchEvents = this.getEventsPage( e );
					this.touchEndY = touchEvents.y;

					if ( Math.abs( this.touchStartY - this.touchEndY ) > ( this.$element.height() / 100 * this.options.touchSensitivity ) ) {
						if ( this.touchStartY > this.touchEndY ) {
							this.scrolling( 'down', scrollable );
						} else if ( this.touchEndY > this.touchStartY ) {
							this.scrolling( 'up', scrollable );
						}
					}
				}
			}
		}

		buildNavigationMarkup() {

			if ( this.$ppNav ) {
				this.$ppNav.remove();
			}

			this.$ppNav = $( `
				<div id="pp-nav">
					<div class="pp-nav-inner">
						<span class="pp-nav-current"><span></span></span>
						<ul class="pp-nav-ul reset-ul"></ul>
					</div>
				</div>
			`);
			this.$ppNavCurrent = $( '.pp-nav-current', this.$ppNav );
			this.$ppNav.children().append( `<span class="pp-nav-total">${ this.$sectionElements.length < 10 ? '0' + this.$sectionElements.length : this.$sectionElements.length }</span>` );

			$( 'body' ).append( this.$ppNav );

		}

		/**
		* Creates a vertical navigation bar.
		*/
		addNavigationItem( i ) {

			this.$ppNavCurrent.find( '> span' ).append( `<span>${ i < 10 ? '0' + ( i + 1 ) : ( i + 1 ) }</span>` );

			this.$ppNav.find( 'ul' ).append( `<li data-tooltip="${ this.tooltips[ i ] }">
				<a href="#${ this.anchors[ i ] }">
					<span></span>
					<svg width="29px" height="29px" viewBox="0 0 29 29" stroke="#000" stroke-width="1" fill="none" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
						<path d="M14.5,28 C21.9558441,28 28,21.9558441 28,14.5 C28,7.04415588 21.9558441,1 14.5,1 C7.04415588,1 1,7.04415588 1,14.5 C1,21.9558441 7.04415588,28 14.5,28 Z"></path>
					</svg>
				</a>
				<span class="pp-tooltip">${ this.tooltips[ i ] }</span>
			</li>`);

		}

		/**
		* Activating the website navigation dots according to the given slide name.
		*/
		activateNavDots( sectionIndex ) {
			this.$ppNav.find( 'li' ).removeClass( 'active' );
			this.$ppNav.find( 'li' ).eq( sectionIndex ).addClass( 'active' );
		}

		updateSections( $sections ) {

			this.$sectionElements = $sections;

			this.buildNavigationMarkup();

			this.addClassnames();

			this.eachSection();

		}

		sectionAppended( section, i ) {

			const sec = $( section ).get( 0 );

			if ( !i ) {
				this.$sectionElements = $( [ ...this.$sectionElements, sec ] );
			} else {
				this.$sectionElements = $( [ ...this.$sectionElements ].splice( i, 0, sec ) );
			}

			this.buildNavigationMarkup();

			this.addClassnames();

			this.eachSection();

		}

		setActiveSection( index ) {

			this.$sectionElements.removeClass( 'active' );
			this.$sectionElements.eq( index ).addClass( 'active' );

		}

		build() {

			this.buildNavigationMarkup();

			if ( $liquidMainFooter.length && !window.frameElement ) {

				let $toAppendFooter;

				if ( liquidIsElementor ) {
					const $secWrap = $liquidContentsWrap.find( ' > .elementor > .elementor-section-wrap' );
					$toAppendFooter = $secWrap.length ? $secWrap : $liquidContentsWrap.find( ' > .elementor' );
				} else {
					$toAppendFooter = $liquidContentsWrap;
				}

				$toAppendFooter.append( $liquidMainFooter );

				this.$sectionElements.last().addClass( 'section-before-footer' );

				this.$sectionElements.push( $liquidMainFooter[ 0 ] );

			}

			// style tags preventing scrolling
			$liquidContentsWrap.children( 'style' ).appendTo( 'head' );
			$liquidContentsWrap.children( 'p' ).insertAfter( $liquidSiteWrap );

		}

		addClassnames() {

			const { options } = this;

			this.$sectionElements.addClass( 'pp-section' );

			$liquidMainFooter.length && $liquidMainFooter.addClass( 'vc_row pp-auto-height' );

			options.navigation && $liquidBody.addClass( 'lqd-stack-has-nav' );

			options.prevNextButtons && $liquidBody.addClass( 'lqd-stack-has-prevnext-buttons' );

			options.pageNumber && $liquidBody.addClass( 'lqd-stack-has-page-numbers' );

			options.effect !== 'none' && $liquidBody.addClass( 'lqd-stack-effect-enabled' );

			$liquidBody.addClass( `lqd-stack-effect-${ options.effect }` );

			$liquidHtml.add( 'html' ).addClass( 'html-pp-enabled overflow-hidden' );

			$liquidBody.addClass( 'pp-enabled' );

		}

		eachSection() {

			$.each( this.$sectionElements, ( i, section ) => {

				this.makeScrollable( section );
				// this.addTableClass(section);
				this.setAnchors( i, section );
				this.setTooltips( i, section );
				section.classList.contains( 'main-footer' ) || this.addNavigationItem( i );

			} );

		}

		setAnchors( i, section ) {

			let anchor = '';
			let hasAnchorAttr = false;

			if ( section.hasAttribute( 'id' ) ) {

				anchor = section.getAttribute( 'id' );

			} else if ( section.hasAttribute( 'data-tooltip' ) ) {

				anchor = section.getAttribute( 'data-tooltip' ).replace( new RegExp( ' ', 'g' ), '-' ).toLowerCase();

			} else {

				if ( !section.hasAttribute( 'data-anchor' ) ) {
					anchor = `${ this.options.defaultTooltip }-${ i + 1 }`;
				} else {
					hasAnchorAttr = true;
					anchor = section.getAttribute( 'data-anchor' );
				}

			}

			this.anchors[ i ] = anchor;

			if ( !hasAnchorAttr ) {
				$( section ).attr( 'data-anchor', anchor );
			}

		}

		setTooltips( i, section ) {

			if ( !section.hasAttribute( 'data-tooltip' ) ) {
				this.tooltips[ i ] = `${ this.options.defaultTooltip } ${ i + 1 }`;
			} else {
				this.tooltips[ i ] = section.getAttribute( 'data-tooltip' );
			}

		}

		init() {

			this.addMouseWheelHandler();
			this.handleKeys();
			this.addTouchHandler();

			if ( !this.lastScrolledDestiny ) {
				this.setActiveSection( 0 );
				this.activateNavDots( 0 );
				this.addLuminosityClassnames( 0 );
			}

			this.scrollToAnchor();

			this.afterRender();

			$( window ).on( 'hashchange', this.hashChangeHandler.bind( this ) );

		}

		appendPrevNextButtons() {

			const { prevNextLabels } = this.options;

			this.$prevNextButtons = $( '<div class="lqd-stack-prevnext-wrap" />' );
			const $prevButton = $( `<button class="lqd-stack-prevnext-button lqd-stack-prev-button">
				<span class="lqd-stack-button-label">${ prevNextLabels.prev }</span>
				<span class="lqd-stack-button-ext">
				</span>
			</button>`);
			const $nextButton = $( `<button class="lqd-stack-prevnext-button lqd-stack-next-button">
				<span class="lqd-stack-button-label">${ prevNextLabels.next }</span>
				<span class="lqd-stack-button-ext">
				</span>
			</button>`);

			this.$prevNextButtons.append( $prevButton.add( $nextButton ) );

			!$liquidBody.children( '.lqd-stack-prevnext-wrap' ).length && $liquidBody.append( this.$prevNextButtons );

		}

		prevNextButtonsEvents() {

			const $prevButton = this.$prevNextButtons.find( '.lqd-stack-prev-button' );
			const $nextButton = this.$prevNextButtons.find( '.lqd-stack-next-button' );

			$prevButton.on( 'click', this.moveSectionUp.bind( this ) );
			$nextButton.on( 'click', this.moveSectionDown.bind( this ) );

		}

		appendPageNumber() {

			let $pageNumber;

			if ( this.$pageNumbersStyle === 'style1' ) $pageNumber = this.appendPageNumbersStyle1();
			if ( this.$pageNumbersStyle === 'style2' ) $pageNumber = this.appendPageNumbersStyle2();

			if ( !this.$pageNumber.length ) {
				$liquidBody.append( $pageNumber );
				this.$pageNumber = $pageNumber;
			}

		}

		appendPageNumbersStyle1() {

			const totalSections = this.$sectionElements.not( '.main-footer' ).length;
			const $pageNumber = $( '<div class="lqd-stack-page-number" />' );
			const $pageNumnerCounter = $( `<span class="lqd-stack-page-number-counter">
				<span class="lqd-stack-page-number-current"></span>
				<span class="lqd-stack-page-number-passed"></span>
			</span>`);
			const $pageNumnerTotal = $( `<span class="lqd-stack-page-number-total">${ totalSections < 10 ? '0' : '' }${ totalSections }</span>` );

			$pageNumber.append( $pageNumnerCounter );
			$pageNumber.append( $pageNumnerTotal );

			return $pageNumber;

		}

		appendPageNumbersStyle2() {

			const $pageNumber = $( '<div class="lqd-stack-page-number" />' );
			const $ppNavClone = this.$ppNav.find( '.pp-nav-ul' ).clone( true );

			$pageNumber.append( $ppNavClone );

			return $pageNumber;

		}

		setPageNumber( index ) {

			$liquidBody.attr( 'data-lqd-stack-page', index );

			this.$pageNumbersStyle === 'style1' && this.setPageNumbersStyle1( index );
			this.$pageNumbersStyle === 'style2' && this.setPageNumbersStyle2( index );

		}

		setPageNumbersStyle1( index ) {

			const $currentPageNumber = this.$pageNumber.find( '.lqd-stack-page-number-current' );
			const $passedPageNumber = this.$pageNumber.find( '.lqd-stack-page-number-passed' );

			$passedPageNumber.html( $currentPageNumber.html() );
			$currentPageNumber.html( `${ index < 10 ? '0' : '' }${ index }` );

		}

		setPageNumbersStyle2( index ) {

			const $li = this.$pageNumber.find( 'li' );

			$li.removeClass( 'active' );
			$li.eq( index - 1 ).addClass( 'active' );

		}

		addDirectionClassname( direction ) {

			if ( direction === 'down' ) {

				$liquidBody.removeClass( 'lqd-stack-moving-up' ).addClass( 'lqd-stack-moving-down' );

			} else if ( direction === 'up' ) {

				$liquidBody.removeClass( 'lqd-stack-moving-down' ).addClass( 'lqd-stack-moving-up' );

			}

		}

		addLuminosityClassnames( index ) {

			// to wait for apppying data-section-luminosity
			fastdom.mutate( () => {

				const $elements = !headerIsDynamicColor ? $liquidBody : $liquidBody.add( $liquidMainHeader ).add( this.$stickyModules );

				$elements
					.removeClass( 'lqd-active-row-dark lqd-active-row-light' )
					.addClass( `lqd-active-row-${ this.$sectionElements.eq( index ).attr( 'data-section-luminosity' ) }` );

			} )

		}

		initShortcodes( $destinationRow, isFirstRender ) {

			!liquidIsMobile() && $( '[data-dynamic-shape]', $destinationRow ).liquidDynamicShape();

			$( '[data-reveal]', $destinationRow ).liquidReveal();
			$( '[data-particles=true]', $destinationRow ).liquidParticles();
			$( '[data-liquid-masonry]', $destinationRow ).liquidMasonry();

			if ( isFirstRender ) {
				this.initInview( $destinationRow, true );
			}

			if (
				liquidIsMobile() &&
				document.body.hasAttribute( 'data-disable-animations-onmobile' )
			) {
				return $( '[data-custom-animations]' ).addClass( 'ca-initvalues-applied' );
			};

			$( '[data-custom-animations]', $destinationRow ).liquidCustomAnimations();
			$destinationRow.is( '[data-custom-animations]' ) && $destinationRow.liquidCustomAnimations();

		}

		initBackToTop( rowIndex ) {

			if ( rowIndex > 1 ) {
				$backToTopButton.addClass( 'is-visible' );
			} else {
				$backToTopButton.removeClass( 'is-visible' );
			}

			$( 'a', $backToTopButton ).on( 'click', ( event ) => {
				event.preventDefault();
				this.moveTo( 1 );
			} );

		}

		afterRender() {

			// Hide the last nav item if it's for the main footer
			if ( $liquidMainFooter.length ) {
				// this.$ppNav.find('li').last().addClass('hide');
				$liquidBody.addClass( 'lqd-stack-has-footer' );
			}

			const activeSectionIndex = this.$sectionElements.get().findIndex( section => section.classList.contains( 'active' ) );

			this.initShortcodes( this.$sectionElements.eq( activeSectionIndex ), true );

			// if it's not in the frontend editor
			if ( !window.frameElement ) {
				this.options.prevNextButtons && this.appendPrevNextButtons();
				this.options.prevNextButtons && this.prevNextButtonsEvents();
				this.options.pageNumber && this.appendPageNumber();
				this.setPageNumber( activeSectionIndex + 1 );
			}

			$liquidBody.addClass( 'lqd-stack-initiated' );

		}

		onLeave( index, nextIndex, direction ) {

			const $destinationRow = $( this.$sectionElements[ nextIndex - 1 ] );
			const $originRow = $( this.$sectionElements[ index - 1 ] );

			if ( !$destinationRow.hasClass( 'main-footer' ) && !$originRow.hasClass( 'main-footer' ) ) {

				this.$ppNav.css( 'pointer-events', 'none' );
				$liquidBody.addClass( 'lqd-stack-moving' );
				this.setPageNumber( nextIndex );

				$destinationRow.removeClass( 'lqd-stack-row-leaving' ).addClass( 'lqd-stack-row-entering' );
				$originRow.removeClass( 'lqd-stack-row-entering' ).addClass( 'lqd-stack-row-leaving' );

				this.addLuminosityClassnames( nextIndex - 1 );

				this.$ppNavCurrent.children( 'span' ).css( {
					transform: `translateY(-${ ( nextIndex - 1 ) * 100 }%)`
				} );

			} else if ( $originRow.hasClass( 'main-footer' ) ) {

				$originRow.addClass( 'lqd-stack-row-leaving' );

			}

			if ( $destinationRow.hasClass( 'main-footer' ) ) {

				$liquidBody.addClass( 'lqd-stack-footer-active' )

				$originRow.css( 'transform', 'none' );

			} else {

				$liquidBody.removeClass( 'lqd-stack-footer-active' )

			}

			this.addDirectionClassname( direction );
			this.initShortcodes( $destinationRow, false );
			$backToTopButton.length && this.initBackToTop( nextIndex );

			if ( this.$mainNavLocalScroll.length ) {
				this.handleMainNavLocalScroll();
			}

		}

		afterLoad( anchorLink, rowIndex ) {

			const $destinationRow = $( this.$sectionElements[ rowIndex - 1 ] );

			$( this.$sectionElements ).removeClass( 'lqd-stack-row-entering lqd-stack-row-leaving' )

			this.$ppNav.css( 'pointer-events', '' );
			$liquidBody.removeClass( 'lqd-stack-moving lqd-stack-moving-up lqd-stack-moving-down' );

			this.initInview( $destinationRow, false );

		}

		initInview( $destinationRow, waitForPreloader ) {

			if ( $liquidBody.hasClass( 'lqd-preloader-activated' ) && waitForPreloader ) {
				document.addEventListener( 'lqd-preloader-anim-done', () => {
					$( '[data-inview]', $destinationRow ).liquidInView();
				} );
			} else {
				$( '[data-inview]', $destinationRow ).liquidInView();
			}

		}

		handleMainNavLocalScroll() {

			const winHash = window.location.hash;

			if ( winHash ) {

				this.$mainNavLocalScroll.find( `a[href="${ winHash }"]` )
					.parent().addClass( 'is-active' )
					.siblings().removeClass( 'is-active' );

				this.$mainNavLocalScroll.closest( '.navbar-fullscreen' ).collapse( 'hide' );
				this.$mainNavLocalScroll.closest( '.navbar-collapse' ).collapse( 'hide' );
				this.$mainNavLocalScroll.closest( '.ld-module-dropdown' ).collapse( 'hide' );

			}

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'stack-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {

				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );

			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {

	if ( !window.frameElement ) {
		$( '[data-liquid-stack=true]' ).liquidStack();
	}

} );