( function ( $ ) {

	'use strict';

	const pluginName = 'liquidAsymmetricSlider';
	let defaults = {
		autoplay: false
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.element = element;
			this.$element = $( element );

			this.DOM = {
				titlesWrap: this.element.querySelector( '.lqd-asym-slider-title-wrap' ),
				infosWrap: this.element.querySelector( '.lqd-asym-slider-info-wrap' ),
				imagesWrap: this.element.querySelector( '.lqd-asym-slider-img-wrap' ),
				titles: [ ...this.element.querySelectorAll( '.lqd-asym-slider-title' ) ],
				infos: [ ...this.element.querySelectorAll( '.lqd-asym-slider-info' ) ],
				images: [ ...this.element.querySelectorAll( '.lqd-asym-slider-img' ) ],
				prevBtn: this.element.querySelector( '.lqd-asym-slider-prev' ),
				nextBtn: this.element.querySelector( '.lqd-asym-slider-next' )
			};

			this.isPlaying = false;
			this.currentSlide = 0;
			this.nextSlide = null;
			this.prevSlide = null;
			this.totalSlides = this.DOM.images.length - 1;

			const promises = [];
			const $firstHeading = $( this.DOM.titlesWrap ).find( '.lqd-asym-slider-title-element' ).first();

			const elementFontFamily = $firstHeading.css( 'font-family' ).replace( /"/g, '' ).replace( /'/g, '' ).split( ',' )[ 0 ];
			const elementFontWeight = $firstHeading.css( 'font-weight' );
			const elementFontStyle = $firstHeading.css( 'font-style' );

			promises.push( new Promise( resolve => imagesLoaded( this.element, resolve ) ) );

			if (
				window.liquidCheckedFonts &&
				window.liquidCheckedFonts.length &&
				window.liquidCheckedFonts.indexOf( elementFontFamily.toLowerCase() ) < 0
			) {

				const font = new FontFaceObserver( elementFontFamily, {
					weight: elementFontWeight,
					style: elementFontStyle
				} );

				promises.push( font.load() );

			}

			Promise.all( promises )
				.finally( () => {
					// setting up IO for cases where user using this plugin in tabs or accordion
					new IntersectionObserver( ( [ entry ], observer ) => {
						if ( entry.isIntersecting ) {
							observer.disconnect();
							this.init();
						}
					} ).observe( this.element );
				} );

		}

		init() {

			const { autoplay } = this.options;

			this.updateHeights();
			this.events();
			this.playInitial();

			this.element.classList.add( 'lqd-asym-slider-ready' );

			if ( autoplay && autoplay > 0 ) {
				this.autoplayInitCall = gsap.delayedCall( 1.5, this.autoplay.bind( this ) );
			};

		}

		autoplay() {

			const { autoplay } = this.options;

			if ( !autoplay || autoplay <= 0 ) return;

			this.autoplayInitCall && this.autoplayInitCall.kill();
			this.autoplayCall = gsap.delayedCall( autoplay, this.next.bind( this ) );

		}

		events() {

			this.DOM.prevBtn.addEventListener( 'click', this.prev.bind( this ) );
			this.DOM.nextBtn.addEventListener( 'click', this.next.bind( this ) );
			window.addEventListener( 'resize', liquidDebounce( this.updateHeights.bind( this ), 1000 ) )

		}

		updateHeights() {

			this.DOM.imagesWrap.style.transition = 'height 0.3s 1s';
			this.DOM.titlesWrap.style.transition = 'height 0.3s 1s';
			this.DOM.infosWrap.style.transition = 'height 0.3s 1s';

			this.DOM.imagesWrap.style.height = `${ this.DOM.images[ this.currentSlide ].offsetHeight }px`;
			this.DOM.titlesWrap.style.height = `${ this.DOM.titles[ this.currentSlide ].offsetHeight }px`;
			this.DOM.infosWrap.style.height = `${ this.DOM.infos[ this.currentSlide ].offsetHeight }px`;

		}

		beforePlay() {

			this.element.classList.add( 'lqd-asym-slider-changing' );
			this.DOM.titles[ this.nextSlide ].classList.add( 'is-next' );
			this.DOM.titles[ this.nextSlide ].classList.remove( 'active' );
			this.DOM.images[ this.nextSlide ].classList.add( 'is-next' );
			this.DOM.images[ this.nextSlide ].classList.remove( 'active' );
			this.DOM.infos[ this.nextSlide ].classList.add( 'is-next' );
			this.DOM.infos[ this.nextSlide ].classList.remove( 'active' );

			this.isPlaying = true;

		}

		afterPlay() {

			this.element.classList.remove( 'lqd-asym-slider-changing' );
			this.DOM.titles[ this.nextSlide ].classList.remove( 'is-next' );
			this.DOM.titles[ this.nextSlide ].classList.add( 'active' );
			this.DOM.titles[ this.prevSlide ].classList.remove( 'active' );
			this.DOM.images[ this.nextSlide ].classList.remove( 'is-next' );
			this.DOM.images[ this.nextSlide ].classList.add( 'active' );
			this.DOM.images[ this.prevSlide ].classList.remove( 'active' );
			this.DOM.infos[ this.nextSlide ].classList.remove( 'is-next' );
			this.DOM.infos[ this.nextSlide ].classList.add( 'active' );
			this.DOM.infos[ this.prevSlide ].classList.remove( 'active' );

			this.isPlaying = false;

			this.autoplayCall && this.autoplayCall.kill();
			this.autoplay();

		}

		playInitial() {

			this.prevSlide = this.currentSlide;
			this.nextSlide = this.currentSlide;

			this.playTitle( 'init' );
			this.playInfo( 'init' );
			this.playImages( 'init' );

		}

		prev() {

			if ( this.isPlaying ) return;

			this.prevSlide = this.currentSlide;
			this.nextSlide = this.currentSlide === 0 ? this.totalSlides : this.currentSlide - 1;
			this.currentSlide = this.nextSlide;

			this.beforePlay();

			this.updateHeights();

			this.playTitle( 'prev' );
			this.playInfo( 'prev' );
			this.playImages( 'prev' ).then( () => {
				this.afterPlay();
			} );

		}

		next() {

			if ( this.isPlaying ) return;

			this.prevSlide = this.currentSlide;
			this.nextSlide = this.currentSlide === this.totalSlides ? 0 : this.currentSlide + 1;
			this.currentSlide = this.nextSlide;

			this.beforePlay();

			this.updateHeights();

			this.playTitle( 'next' );
			this.playInfo( 'next' );
			this.playImages( 'next' ).then( () => {
				this.afterPlay();
			} );

		}

		playTitle( dir ) {

			const currentTitle = this.DOM.titles[ this.prevSlide ];
			const nextTitle = this.DOM.titles[ this.nextSlide ];
			const currentTitleChars = currentTitle.querySelectorAll( '.lqd-chars' );
			const nextTitleChars = nextTitle.querySelectorAll( '.lqd-chars' );

			const timeline = gsap.timeline( {
				defaults: {
					duration: 1,
				},
				delay: dir === 'next' ? 0.15 : 0
			} );

			if ( dir === 'prev' ) {
				timeline
					.fromTo( [ ...currentTitleChars ].reverse(),
						{ y: '0%', rotation: 0, opacity: 1 },
						{ y: '100%', rotation: 15, opacity: 0, ease: 'expo.inOut', stagger: 0.025 }
					)
					.fromTo( [ ...nextTitleChars ].reverse(),
						{ y: '-100%', rotation: 15, opacity: 0 },
						{ y: '0%', rotation: 0, opacity: 1, ease: 'expo.out', stagger: 0.025 },
						0.75 )
			} else if ( dir === 'next' ) {
				timeline
					.fromTo( currentTitleChars,
						{ y: '0%', rotation: 0, opacity: 1 },
						{ y: '-100%', rotation: 15, opacity: 0, ease: 'expo.inOut', stagger: 0.025 }
					)
					.fromTo( nextTitleChars,
						{ y: '100%', rotation: 15, opacity: 0 },
						{ y: '0%', rotation: 0, opacity: 1, ease: 'expo.out', stagger: 0.025 },
						0.75 )
			} else {
				timeline
					.fromTo( currentTitleChars,
						{ x: 35, opacity: 0 },
						{ x: 0, opacity: 1, ease: 'expo.inOut', stagger: 0.045 }
					)
			}

		}

		playInfo( dir ) {

			const currentInfo = this.DOM.infos[ this.prevSlide ];
			const currentInfoTitle = currentInfo.querySelector( '.lqd-asym-slider-subtitle-element' );
			const currentInfoP = currentInfo.querySelector( '.lqd-asym-slider-description-element' );
			const currentInfoHr = currentInfo.querySelector( 'hr' );
			const nextInfo = this.DOM.infos[ this.nextSlide ];
			const nextInfoTitle = nextInfo.querySelector( '.lqd-asym-slider-subtitle-element' );
			const nextInfoP = nextInfo.querySelector( '.lqd-asym-slider-description-element' );

			const timeline = gsap.timeline( {
				defaults: {
					ease: 'expo.inOut',
					duration: 1.5
				},
				delay: dir === 'prev' ? 0.3 : 0.15
			} );

			if ( dir === 'prev' ) {
				timeline
					.fromTo( currentInfoTitle,
						{ x: 0, opacity: 1 },
						{ x: 15, opacity: 0 },
						0 )
					.fromTo( currentInfoP,
						{ x: 0, opacity: 1 },
						{ x: 15, opacity: 0 },
						0.15 )
					.fromTo( nextInfoTitle,
						{ x: -15, opacity: 0 },
						{ x: 0, opacity: 1 },
						0.15 )
					.fromTo( nextInfoP,
						{ x: -15, opacity: 0 },
						{ x: 0, opacity: 1 },
						0.3 )
			} else if ( dir === 'next' ) {
				timeline
					.fromTo( currentInfoTitle,
						{ x: 0, opacity: 1 },
						{ x: -15, opacity: 0 },
						0 )
					.fromTo( currentInfoP,
						{ x: 0, opacity: 1 },
						{ x: -15, opacity: 0 },
						0.15 )
					.fromTo( nextInfoTitle,
						{ x: 15, opacity: 0 },
						{ x: 0, opacity: 1 },
						0.15 )
					.fromTo( nextInfoP,
						{ x: 15, opacity: 0 },
						{ x: 0, opacity: 1 },
						0.3 )
			} else {
				timeline
					.fromTo( currentInfoTitle,
						{ x: 30, opacity: 0 },
						{ x: 0, opacity: 1 },
						0 )
					.fromTo( currentInfoHr,
						{ scaleX: 0.6, opacity: 0 },
						{ scaleX: 1, opacity: 1 },
						0 )
					.fromTo( currentInfoP,
						{ x: 30, opacity: 0 },
						{ x: 0, opacity: 1 },
						0.15 )
			}

		}

		playImages( dir ) {

			const currentImage = this.DOM.images[ this.prevSlide ];
			const currentImageInner = currentImage.querySelector( '.lqd-asym-slider-img-inner' );
			const nextImage = this.DOM.images[ this.nextSlide ];
			const nextImageInner = nextImage.querySelector( '.lqd-asym-slider-img-inner' );

			const timeline = gsap.timeline( {
				defaults: {
					ease: 'expo.inOut',
					duration: 1.5
				},
				delay: dir === 'prev' ? 0.15 : 0
			} );

			if ( dir === 'prev' ) {
				timeline
					.fromTo( currentImageInner,
						{ x: '0%', scale: 1 },
						{ x: '-100%', scale: 1.2 },
						0 )
					.fromTo( currentImage,
						{ x: '0%' },
						{ x: '100%' },
						0 )
					.fromTo( nextImage,
						{ x: '-100%' },
						{ x: '0%' },
						0 )
					.fromTo( nextImageInner,
						{ x: '100%', scale: 1.2 },
						{ x: '0%', scale: 1 },
						0 )
			} else if ( dir === 'next' ) {
				timeline
					.fromTo( currentImageInner,
						{ x: '0%', scale: 1 },
						{ x: '100%', scale: 1.2 },
						0 )
					.fromTo( currentImage,
						{ x: '0%' },
						{ x: '-100%' },
						0 )
					.fromTo( nextImage,
						{ x: '100%' },
						{ x: '0%' },
						0 )
					.fromTo( nextImageInner,
						{ x: '-100%', scale: 1.2 },
						{ x: '0%', scale: 1 },
						0 )
			} else {
				timeline
					.fromTo( currentImageInner,
						{ x: '100%', scale: 1.2 },
						{ x: '0%', scale: 1 },
						0 )
					.fromTo( currentImage,
						{ x: '-100%' },
						{ x: '0%' },
						0 )
			}

			return timeline;

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'asym-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {
	$( '[data-asym-slider]' ).liquidAsymmetricSlider();
} );