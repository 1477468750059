( function ( $ ) {

	'use strict';

	const pluginName = 'liquidSetActiveOnhover';
	let defaults = {
		classname: 'lqd-is-active',
		offClassname: 'lqd-was-active',
		triggerHandlers: [ 'mouseenter', 'mouseleave' ], // on, off
		triggers: '> li',
		targets: '',
		lazyLoadImgVid: false
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.DOM = {};
			this.DOM.element = element;
			this.DOM.$element = $( element );
			this.DOM.$triggers = this.options.triggers === 'self' ? this.DOM.$element : this.DOM.$element.find( this.options.triggers );
			this.DOM.$targets = this.options.targets === 'self' ? this.DOM.$element : this.DOM.$element.find( this.options.targets );

			if ( this.DOM.$element.parents( '[data-lqd-fullproj]' ).length ) {
				this.DOM.$fullscreenProjectParent = this.DOM.$element.parents( '[data-lqd-fullproj]' );
			}

			this.listenToFullscreenProjectParent();
			this.init();

		}

		listenToFullscreenProjectParent() {

			if ( this.DOM.$targets.length > 1 || this.DOM.$trigger > 1 ) return;

			const { classname, offClassname } = this.options;
			const $firstTarget = this.DOM.$targets.eq( 0 );
			const $firstTrigger = this.DOM.$triggers.eq( 0 );

			// autoplay the video if it's the only item in fullscreen project
			this.DOM.$fullscreenProjectParent && this.DOM.$fullscreenProjectParent.on( 'show.bs.collapse', () => {
				console.log( this.DOM.$targets );
				$firstTarget.add( $firstTrigger ).addClass( classname );
				$firstTarget.add( $firstTrigger ).removeClass( offClassname );
				$firstTarget.add( $firstTrigger ).css( 'pointer-events', 'none' );
				this.handleLazyload( $firstTarget.add( $firstTrigger ) );
			} );
			this.DOM.$fullscreenProjectParent && this.DOM.$fullscreenProjectParent.on( 'hide.bs.collapse', () => {
				$firstTarget.add( $firstTrigger ).removeClass( classname );
				$firstTarget.add( $firstTrigger ).removeClass( offClassname );
				$firstTarget.add( $firstTrigger ).css( 'pointer-events', '' );
				$firstTarget.find( 'video' )[ 0 ]?.pause();
			} );

		}

		init() {

			const { triggerHandlers, classname, offClassname, lazyLoadImgVid } = this.options;

			this.DOM.$triggers.each( ( i, trigger ) => {

				const $trigger = $( trigger );
				let $target = this.DOM.$targets.eq( i );

				if ( !$target.length ) {
					$target = $trigger;
				}

				if ( triggerHandlers[ 0 ] === triggerHandlers[ 1 ] ) {

					$trigger.on( triggerHandlers[ 0 ], () => {
						$target.add( trigger ).toggleClass( classname );
					} )

				} else {

					$trigger.on( triggerHandlers[ 0 ], () => {
						this.DOM.$targets.add( this.DOM.$triggers ).removeClass( classname );
						$target.add( trigger ).addClass( classname );
						$target.add( trigger ).removeClass( offClassname );
						lazyLoadImgVid && this.handleLazyload( $target );
					} );

					if ( triggerHandlers[ 1 ] != null ) {
						$trigger.on( triggerHandlers[ 1 ], () => {
							$target.add( trigger ).removeClass( classname );
							$target.add( trigger ).addClass( offClassname );
						} );
						$( document ).on( 'click.lqdActiveOnHoverClick', event => {
							if ( !$target[ 0 ].contains( event.target ) ) {
								$target.add( trigger ).removeClass( classname );
								$target.add( trigger ).addClass( offClassname );
							}
						} )
					}

				}

			} )


		}

		handleLazyload( $target ) {

			const $imgVids = $target.find( 'img, source' );

			$imgVids.each( ( i, imgVid ) => {

				const $imgVid = $( imgVid );
				const src = $imgVid.attr( 'data-src' );

				if ( src ) {
					$imgVid.attr( 'src', src );
				}

				if ( $imgVid.is( 'source' ) && i === $imgVids.length - 1 ) {
					const vid = $imgVid.parent( 'video' )[ 0 ];
					vid.load();
					vid.play();
				}

			} );

		}

		destroy() {

			const { triggers } = this.options;
			const $triggers = this.DOM.$element.find( triggers );

			$triggers.each( ( i, trigger ) => {
				$( trigger ).off();
			} )

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'active-onhover-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {
	$( '[data-active-onhover]' ).liquidSetActiveOnhover();
} );