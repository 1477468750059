( function ( $ ) {
	'use strict';

	const pluginName = 'liquidBgColor';

	let defaults = {
		getBgFromSelector: 'backgroundColor', // 'backgroundColor', 'borderColor'
		setBgTo: 'self', // 'self', 'css-selector'
		manipulateColor: null, // [{'saturate': 40}, {'lighten': 15}]
		changeBorderColor: false,
		interactWithHeader: false,
		makeGradient: false
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.element = element;
			this.$element = $( element );
			this.$stickyModules = this._getStickyModules();
			this.setBgToEls = this.options.setBgTo === 'self' ? [ this.element ] : $( this.options.setBgTo, this.element ).get();
			this.$bgEl = false;

			this.rowsRect = [];

			this.colors = [];

			this.direction = 0;

			this.$element.is( $liquidContents ) && this._addBgElement();

			this.liquidBgColorInitPromise = new Promise( resolve => {
				this.$element.on( 'lqd-bg-color-init', resolve.bind( this, this ) )
			} );

			LiquidSectionsDetails.getDetails().then( sections => {

				$( sections ).imagesLoaded( this._init( sections ) );

			} );

		}

		_init( liquidSections ) {

			const sections = liquidSections.filter( sec => !sec.isHidden && !sec.isInFooter ).filter( sec => !sec.isInnerSection );

			sections.forEach( async ( sec, i ) => {

				await this._getColors( sec );

				Promise.all( this._getRects( sec, i ) ).then( () => {
					this._setupIO( sec, i );
					if ( this.$bgEl ) {
						this.element.classList.add( 'bg-transparent' );
						sec.el.classList.add( 'bg-transparent' );
						sec.isInnerSection && sec.parentSection && sec.parentSection.el.classList.add( 'bg-transparent' );
					}
				} );

			} );

			this.$element.trigger( 'lqd-bg-color-init', this.element );

		}

		_getStickyModules() {

			let stickyModules = false;

			if ( $liquidMainHeader.length && $liquidMainHeader[ 0 ].hasAttribute( 'data-sticky-header' ) ) {

				if ( liquidIsElementor ) {
					stickyModules = $liquidMainHeader.find( '> .elementor' ).find( '> .elementor-section-wrap > .elementor-section, > .elementor-section, > .elementor-section-wrap > .e-container, > .e-container, > .e-con' ).not( '.lqd-hide-onstuck' ).find( '[data-element_type="widget"]' );
				} else {
					stickyModules = $liquidMainHeader.find( '.lqd-head-sec-wrap, .lqd-stickybar-wrap' ).not( '.lqd-hide-onstuck' ).find( '.header-module' )
				}

			}

			return stickyModules;

		}

		_addBgElement() {

			if ( this.$bgEl ) return;

			const overflowClassname = liquidIsMobile() ? 'overflow-hidden' : '';
			const posClassname = liquidIsMobile() ? 'pos-fix' : 'pos-sticky';
			const heightClassname = liquidIsMobile() ? 'h-100' : 'h-vh-100';

			this.$bgEl = $( `<div class="lqd-liquid-bg-el-wrap lqd-overlay pointer-events-none z-index--1 ${ overflowClassname }"><div class="lqd-liquid-bg-el ${ posClassname } pos-tl w-100 top-0 left-0 w-full ${ heightClassname } pointer-events-none"></div></div>` );

			this.$bgEl.prependTo( this.$element );

			this.setBgToEls = [ this.$bgEl.children()[ 0 ] ];

			if ( $liquidMainFooter.length && !$liquidMainFooter[ 0 ].hasAttribute( 'data-sticky-footer' ) || liquidIsMobile() ) {
				$liquidMainFooter.css( {
					position: 'relative',
					zIndex: 2
				} )
			}

		}

		_getColors( lqdSection ) {

			return fastdomPromised.measure( () => {

				const { getBgFromSelector, manipulateColor } = this.options;

				const obj = {};
				let color = lqdSection[ getBgFromSelector ].replace( /, /g, ',' ).split( ' ' )[ 0 ];
				let dataLuminosityAttr = lqdSection.$el.attr( 'data-section-luminosity' );

				if ( lqdSection.isInnerSection && lqdSection.parentSection ) {
					if ( lqdSection.isBgTransparent && !lqdSection.parentSection.isBgTransparent ) {
						color = lqdSection.parentSection[ getBgFromSelector ].replace( /, /g, ',' ).split( ' ' )[ 0 ];
					}
				}
				if ( lqdSection.isBgTransparent ) {
					color = $liquidContents.css( 'backgroundColor' );
				}

				if ( manipulateColor && manipulateColor.length > 0 ) {
					for ( let i = 0; i < manipulateColor.length; i++ ) {
						color = tinycolor( color )[ Object.keys( manipulateColor[ i ] )[ 0 ] ]( Object.values( manipulateColor[ i ] )[ 0 ] ).toString();
					}
				}

				obj.color = color;
				obj.luminosity = dataLuminosityAttr != null && !manipulateColor ? dataLuminosityAttr : tinycolor( color ).getLuminance() <= 0.4 ? 'dark' : 'light';

				this.colors.push( obj );

			} )

		}

		_interactWithHeader( colorLuminosity ) {

			const $elements = $liquidMainHeader.add( this.$stickyModules ).filter( ( i, el ) => {
				const $el = $( el );
				return (
					!$el.children( '.navbar-brand-solid' ).length && !$el.hasClass( 'navbar-brand-solid' ) &&
					!$el.find( '> .elementor-widget-container > .navbar-brand-solid' ).length
				)
			} );

			if ( colorLuminosity === 'dark' ) {
				$elements.addClass( 'lqd-active-row-dark' ).removeClass( 'lqd-active-row-light' );
			} else {
				$elements.addClass( 'lqd-active-row-light' ).removeClass( 'lqd-active-row-dark' );
			}

		}

		_interactWithColors( element, targetColorLuminosity ) {

			const $el = $( element );

			if ( $el.hasClass( 'btn-icon' ) || $el.hasClass( 'btn-solid' ) ) {
				const buttonColor = targetColorLuminosity === 'dark' ? '#fff' : '#000';
				$el.css( {
					transition: 'box-shadow 0.3s, transform 0.3s, color 0.3s',
					color: buttonColor
				} );
			}

			if ( $el.hasClass( 'navbar-brand-inner' ) ) {
				const $parentModule = liquidIsElementor ? $el.closest( '.elementor-element' ) : $el.closest( '.header-module' );
				if ( targetColorLuminosity === 'dark' ) {
					$parentModule.addClass( 'lqd-active-row-dark' ).removeClass( 'lqd-active-row-light' );
				} else {
					$parentModule.addClass( 'lqd-active-row-light' ).removeClass( 'lqd-active-row-dark' );
				}
			}

		}

		_getRects( lqdSection, loopIndex ) {

			const promises = [];
			let row = lqdSection.el.querySelector( '.ld-row' );

			if ( liquidIsElementor ) {
				if ( lqdSection.el.classList.contains( 'e-container' ) || lqdSection.el.classList.contains( 'e-con' ) ) {
					row = lqdSection.el;
				} else {
					row = lqdSection.el.querySelector( '.elementor-container' );
				}
			}

			const rowPromise = new Promise( resolve => {
				new IntersectionObserver( ( [ entry ], observer ) => {
					observer.disconnect();
					this.rowsRect[ loopIndex ] = entry.boundingClientRect;
					resolve();
				} ).observe( row );
			} );

			promises.push( rowPromise );

			return promises;

		}

		_setupIO( lqdSection, loopIndex ) {

			const timeline = gsap.timeline();
			const start = loopIndex === 0 ? 'top bottom' : `top+=${ this.rowsRect[ loopIndex ].y - lqdSection.rect.y } bottom`;

			this.setBgToEls.forEach( element => {

				const onUpdate = () => {
					const i = this.direction < 1 && loopIndex > 0 ? loopIndex - 1 : loopIndex;
					this.options.interactWithHeader && this.$stickyModules && this._interactWithHeader( this.colors[ i ].luminosity );
					this._interactWithColors( element, this.colors[ i ].luminosity );
				};

				timeline.fromTo( element,
					{
						backgroundColor: loopIndex === 0 ? this.colors[ loopIndex ].color : this.colors[ loopIndex - 1 ].color
					},
					{
						backgroundColor: this.colors[ loopIndex ].color,
						onUpdate,
						onComplete: () => {
							element.style.transition = '';
						},
					}, 0
				);

			} );

			ScrollTrigger.create( {
				animation: timeline,
				trigger: lqdSection.el,
				start,
				end: `+=${ this.rowsRect[ loopIndex ].height }`,
				scrub: 0.1,
				onUpdate: st => {
					this.direction = st.direction;
				},
				// markers: {
				// 	indent: (loopIndex * 150),
				// 	startColor: tinycolor(this.colors[loopIndex].color).darken(20),
				// 	endColor: tinycolor(this.colors[loopIndex].color).darken(20),
				// }
			} );

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'liquid-bg-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {

	const $liquidBgEls = $( '[data-liquid-bg]' );

	$liquidBgEls.liquidBgColor();

	if ( liquidIsElementor ) {

		$liquidBgEls.each( ( i, el ) => {

			const $el = $( el );

			if (
				$el.is( $liquidContents ) &&
				$liquidMainHeader.length &&
				!$liquidMainHeader.attr( 'data-liquid-bg' )
			) {

				$liquidMainHeader.liquidBgColor( {
					setBgTo: `
						> .elementor > .elementor-section-wrap > .elementor-section:not(.lqd-hide-onstuck) > .elementor-container > .elementor-column > .elementor-widget-wrap > .elementor-widget-ld_header_image .navbar-brand-solid .navbar-brand-inner,
						> .elementor > .elementor-section-wrap > .elementor-section:not(.lqd-hide-onstuck) > .elementor-container > .elementor-column > .elementor-widget-wrap > .elementor-widget-ld_button .btn-solid,
						> .elementor > .elementor-section-wrap > .elementor-section:not(.lqd-hide-onstuck) > .elementor-container > .elementor-column > .elementor-widget-wrap > .elementor-widget-ld_button .btn-icon-solid .btn-icon,
						> .elementor > .elementor-section:not(.lqd-hide-onstuck) > .elementor-container > .elementor-column > .elementor-widget-wrap > .elementor-widget-ld_header_image .navbar-brand-solid .navbar-brand-inner,
						> .elementor > .elementor-section:not(.lqd-hide-onstuck) > .elementor-container > .elementor-column > .elementor-widget-wrap > .elementor-widget-ld_button .btn-solid,
						> .elementor > .elementor-section:not(.lqd-hide-onstuck) > .elementor-container > .elementor-column > .elementor-widget-wrap > .elementor-widget-ld_button .btn-icon-solid .btn-icon`,
					manipulateColor: [ { 'darken': 30 }, { 'brighten': 15 }, { 'saturate': 20 } ]
				} )

			}

		} );

	}


} );