( function ( $ ) {

	'use strict';

	const pluginName = 'liquidLightBox';
	let defaults = {
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = $.extend( {}, defaults, options );

			this.isOpen = false;
			this.$modalWrapper = false;
			this.$backdrop = false;
			this.lityInstance = false;

			this.element = element;
			this.$element = $( element );

			this.init();

		}

		init() {

			this.events();

		}

		events() {

			$( document ).on( 'lity:ready', ( ev, instance ) => this.onReady.call( this, ev, instance ) );
			$( document ).on( 'lity:open', this.onOpen.bind( this ) );
			$( document ).on( 'lity:close', this.onClose.bind( this ) );

		}

		onReady( event, instance ) {

			const $modalWrapper = $( instance.element() );
			const $modalEl = $( event.target );
			const $opener = $( instance.opener() )
			const modalType = $modalEl.attr( 'data-modal-type' );

			$modalWrapper.attr( 'data-modal-type', modalType );

			if ( modalType === 'box' ) {
				this.boxModalPosition( $modalWrapper, $opener );
			} else {
				$modalWrapper.removeClass( 'to-left pos-fix' );
				$modalWrapper.css( {
					top: '',
					bottom: '',
					left: '',
					right: ''
				} )
			}

		}

		onOpen( event, instance ) {

			this.lityInstance = instance;
			this.$modalWrapper = $( event.target );
			this.$backdrop = this.$modalWrapper.children( '.lity-backdrop' );
			this.isOpen = true;

			this.lityInstance.element().show();
			this.lityInstance.opener().addClass( 'pointer-events-none' );
			this.$backdrop.on( 'click.lqdModal', this.onBackdropClick.bind( this ) );

		}

		onClose( event ) {

			const $target = $( event.target );
			const $video = $target.find( 'video' );
			const $audio = $target.find( 'audio' );
			const $iframe = $target.find( 'iframe' );

			if ( $video.length ) {
				const video = $video.get( 0 );
				video.oncanplay = video.pause();
			}

			if ( $audio.length ) {
				const audio = $audio.get( 0 );
				audio.oncanplay = audio.pause();
			}

			$iframe.each( ( i, iframe ) => {
				if ( !( !!( iframe.contentDocument ) ) ) return;
				const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
				const video = iframeDoc.querySelector( 'video' );
				const audio = iframeDoc.querySelector( 'audio' );
				video && video.pause();
				audio && audio.pause();
			} )

			this.lityInstance.opener().blur();
			this.lityInstance.opener().removeClass( 'pointer-events-none' );
			this.$modalWrapper.removeClass( 'to-left' );

			this.lityInstance = false;
			this.isOpen = false;
			this.$modalWrapper = false;

			$( document ).off( 'click.lqdModal' );

		}

		boxModalPosition( $modalWrapper, $opener ) {

			const openerOffset = $opener.offset();
			const modalWidth = $modalWrapper.outerWidth();
			const modalHeight = $modalWrapper.outerHeight();
			const offset = openerOffset.left + $opener.outerWidth() - 60;
			const windowWidth = liquidWindowWidth();

			if ( $opener.closest( '.lqd-stickybar-wrap' ).length ) {
				$modalWrapper.addClass( 'pos-fix' );
				$modalWrapper.css( {
					top: openerOffset.top - window.scrollY - modalHeight - 25,
				} );
			} else {
				$modalWrapper.removeClass( 'pos-fix' );
				$modalWrapper.css( {
					top: openerOffset.top - modalHeight - 25,
				} );
			}

			$modalWrapper.css( {
				left: offset,
				right: offset + modalWidth >= windowWidth ? windowWidth - offset : 'auto'
			} );

			if ( offset + modalWidth >= windowWidth ) {

				$modalWrapper.addClass( 'to-left' );

				$modalWrapper.css( {
					left: 'auto',
					right: windowWidth - offset - 60
				} );

			}

			if ( windowWidth <= 480 ) {
				$modalWrapper.css( {
					left: ( windowWidth / 2 ) - ( modalWidth / 2 ),
					right: 'auto',
				} );
			}

		}

		onBackdropClick() {

			if ( !this.isOpen || !this.$modalWrapper || !this.lityInstance ) return;

			this.lityInstance.close();

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'lightbox-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery, window, document ) );

jQuery( document ).ready( function ( $ ) {
	$( '[data-lity]' ).liquidLightBox();
} );