( function ( $ ) {

	'use strict';

	const pageStacksEnabled = $liquidContents.attr( 'data-liquid-stack' ) != null;
	const bodyLSOffset = $liquidBody.attr( 'data-localscroll-offset' );
	const localscrollGlobalOffset = bodyLSOffset != null && bodyLSOffset !== '' ? parseInt( bodyLSOffset, 10 ) : 0;

	const pluginName = 'liquidLocalScroll';
	let defaults = {
		itemsSelector: 'self',
		scrollSpeed: 600,
		scrollBelowSection: false,
		offsetElements: '#wpadminbar, .main-header[data-sticky-header] .lqd-head-sec-wrap:not(.lqd-hide-onstuck), body.elementor-page .main-header[data-sticky-header] > .elementor > .elementor-section-wrap > .elementor-section:not(.lqd-hide-onstuck):not(.lqd-stickybar-wrap), body.elementor-page .main-header[data-sticky-header] > .elementor > .elementor-section:not(.lqd-hide-onstuck):not(.lqd-stickybar-wrap), .lqd-custom-menu[data-pin]:not(.lqd-sticky-menu-floating)',
		includeParentAsOffset: false,
		trackWindowScroll: false
	};

	class Plugin {

		constructor( element, options ) {

			this._defaults = defaults;
			this._name = pluginName;

			this.options = { ...defaults, ...options };

			this.element = element;
			this.$element = $( element );
			this.$items = this.options.itemsSelector === 'self' ? this.$element : $( this.options.itemsSelector, this.$element );
			this.targetsArray = [];
			this.offset = [];
			this.targetsRects = [];
			this.windowHeight = window.innerHeight;

			if (
				this.options.includeParentAsOffset &&
				this.element.parentElement.classList.contains( 'lqd-sticky-menu-floating' )
			) {
				this.options.includeParentAsOffset = false;
			}

			if ( !pageStacksEnabled ) {

				$liquidContents.imagesLoaded( async () => {

					this.$items.each( ( i, item ) => {
						this.getTargetsArray( i, item );
					} );

					if ( this.options.trackWindowScroll ) {
						this.offset = await this.getOffsets();
					}

					this.targetsRects = await this.getTargetsRects();

					this.init();

				} )

			} else {

				this.init();

			}


		}

		async getTargetsRects() {

			const promises = [];

			this.targetsArray.forEach( target => {

				const promise = new Promise( resolve => {

					if ( target == null ) {
						return resolve( null );
					}

					new IntersectionObserver( ( [ entry ], observer ) => {

						const { boundingClientRect } = entry;
						const { scrollY } = window;
						const rect = {
							y: boundingClientRect.y + scrollY,
							bottom: boundingClientRect.bottom + scrollY,
							height: boundingClientRect.height,
							el: entry.target
						}

						resolve( rect );

						observer.disconnect();

					} ).observe( target );

				} );

				promises.push( promise );

			} );

			const rects = await Promise.all( promises );

			return [ ...rects ]

		}

		async getOffsets() {

			const { offsetElements, includeParentAsOffset } = this.options;
			let offset = localscrollGlobalOffset;

			if ( !offsetElements ) {
				return offset;
			}

			const $elements = !includeParentAsOffset ? $( offsetElements ) : $( offsetElements ).add( this.$element.parent() );
			const promises = [];

			$elements.each( ( i, element ) => {

				const promise = new Promise( resolve => {
					new IntersectionObserver( ( [ entry ], observer ) => {
						observer.disconnect();
						resolve( entry.boundingClientRect.height );
					} ).observe( element );
				} )

				promises.push( promise );

			} );

			const heights = await Promise.all( promises );

			heights.forEach( height => offset += height );

			return offset;

		}

		getTargetsArray( itemIndex, item ) {

			if ( this.options.scrollBelowSection ) {
				const $parentRow =
					liquidIsElementor ?
						this.$element.parents( '.elementor-section, .e-container, .e-con' ).last() :
						this.$element.parents( '.vc_row, .vc_section' ).last();
				const $nextRow =
					liquidIsElementor ?
						$parentRow.nextAll( '.elementor-section, .e-container, .e-con' ).first() :
						$parentRow.nextAll( '.vc_row, .vc_section' ).first();
				return this.targetsArray[ itemIndex ] = $nextRow[ 0 ];
			}

			const itemHref = item.getAttribute( 'href' );

			if ( !itemHref ) {
				return this.targetsArray[ itemIndex ] = null;
			}

			const { hash } = item;

			if ( !hash || hash == '' || !$( hash ).length ) {
				return this.targetsArray[ itemIndex ] = null;
			}

			this.targetsArray[ itemIndex ] = document.querySelector( hash );

		}

		init() {

			this.$items.each( ( i, item ) => {
				this.events( i, item );
			} );

			if ( this.options.trackWindowScroll && !pageStacksEnabled ) {
				this.onScroll();
				$liquidWindow.on( 'scroll', this.onScroll.bind( this ) );
			}

		}

		events( itemIndex, item ) {

			$( item ).on( 'click', { itemIndex }, this.onClick.bind( this ) );

			$( document ).on( 'lqd-masonry-layout-init', async () => {
				this.targetsRects = await this.getTargetsRects();
			} );

		}

		onClick( event ) {

			const { itemIndex } = event.data;

			if ( !this.targetsRects[ itemIndex ] ) return;

			const { y } = this.targetsRects[ itemIndex ];

			event.preventDefault();
			event.stopPropagation();

			$liquidHtml.removeClass( 'overflow-hidden' );

			$( 'html, body' ).animate( {
				scrollTop: y - localscrollGlobalOffset
			}, this.options.scrollSpeed )

			this.$element.closest( '.navbar-fullscreen' ).collapse( 'hide' );
			this.$element.closest( '.navbar-collapse' ).collapse( 'hide' );
			this.$element.closest( '.ld-module-dropdown' ).collapse( 'hide' );

		}

		onScroll() {

			/* ScrollSpy JavaScript Library v1.0.0
			 https://scrollspy.github.io */

			let minScrollTime = 200;
			let now = new Date().getTime();

			const processScroll = () => {

				let ScrollPos = this.getScrollPos();
				let StagesPositionPercentage = [];
				let currentElement;

				this.targetsRects.forEach( rect => {

					if ( !rect ) return;

					let Percent = [ rect.y, this.getVisibilityPercent( rect, ScrollPos ) ];

					if ( Percent[ 1 ] !== 0 ) {
						StagesPositionPercentage.push( Percent );
					}

				} );

				if ( StagesPositionPercentage.length === 0 ) {
					StagesPositionPercentage[ 0 ] = [ 0, 0 ];
					StagesPositionPercentage[ 1 ] = [ 0, 0 ];
					this.fakePercent = true;
				} else {
					this.fakePercent = false;
				}

				if ( StagesPositionPercentage.length === 1 ) {
					StagesPositionPercentage[ 1 ] = [ 0, 0 ];
				}


				let max = StagesPositionPercentage.reduce( ( a, b ) => {
					return Math.max( a[ 1 ], b[ 1 ] );
				} );

				if ( isNaN( max ) ) {

					let TempStagesPositionPercentage = [];

					StagesPositionPercentage.forEach( objectPositionPercentage => {

						this.targetsRects.forEach( rect => {

							if ( !rect ) return;

							if ( rect.y === objectPositionPercentage[ 0 ] ) {

								let DistanceFromCenter = [ rect.y, this.getVisibilityDistanceFromCenter( rect, ScrollPos ) ];

								if ( !( TempStagesPositionPercentage.includes( DistanceFromCenter ) ) ) {
									TempStagesPositionPercentage.push( DistanceFromCenter );
								}

							}

						} );

					} );

					TempStagesPositionPercentage.reduce( ( a, b ) => {
						if ( typeof a !== 'undefined' && typeof b != "undefined" ) {
							max = ( Math.min( a[ 1 ], b[ 1 ] ) );
						}
					} );

					StagesPositionPercentage = TempStagesPositionPercentage;

				}

				if ( max !== this.lastItemPercent ) {

					this.lastItemPercent = max;
					StagesPositionPercentage.forEach( objectPositionPercentage => {

						if ( this.firstScroll && !( this.fakePercent ) ) {
							objectPositionPercentage[ 1 ] = max;
							this.firstScroll = false;
							this.CurrentPositionTop = 0;
							this.lastItemPercent = 0;
						}

						if ( objectPositionPercentage[ 1 ] === max && objectPositionPercentage[ 0 ] !== this.CurrentPositionTop && !( this.fakePercent ) ) {

							this.CurrentPositionTop = objectPositionPercentage[ 0 ];

							this.$items.parent().removeClass( 'is-active' );

							this.targetsRects.forEach( ( rect, i ) => {

								if ( !rect ) return;

								if ( rect.y === objectPositionPercentage[ 0 ] ) {
									currentElement = rect.el;
									this.$items.eq( i ).parent().addClass( 'is-active' );
								}

							} );

						}

					} );

				}

			};

			if ( !this.scrollTimer ) {
				if ( now - this.lastScrollFireTime > ( 3 * minScrollTime ) ) {
					processScroll(); // fire immediately on first scroll
					this.lastScrollFireTime = now;
				}
				this.scrollTimer = setTimeout( () => {
					this.scrollTimer = null;
					this.lastScrollFireTime = new Date().getTime();
					processScroll();
				}, minScrollTime );
			} else {
				processScroll();
			}

		}

		checkIsInView( rect, ScrollPos = this.getScrollPos() ) {

			return ( ( rect.y > ScrollPos[ 0 ] ) && ( rect.y < ScrollPos[ 1 ] ) ) || ( ( ( rect.bottom ) > ScrollPos[ 0 ] ) && ( ( rect.bottom ) < ScrollPos[ 1 ] ) ) || ( ( rect.y < ScrollPos[ 0 ] ) && ( ( rect.bottom ) > ScrollPos[ 1 ] ) );

		}

		getScrollPos() {
			let offsetTop;
			let offsetBottom;
			offsetTop = window.scrollY || 0;
			offsetBottom = offsetTop + this.windowHeight;
			return [ offsetTop, offsetBottom ];
		};

		getVisibilityPercent( targetRect, ScrollPos = this.getScrollPos() ) {

			if ( !this.checkIsInView( targetRect, ScrollPos ) ) return 0;

			let pageTop = ScrollPos[ 0 ];
			let pageBottom = ScrollPos[ 1 ];

			let visiblePixle = 0;

			if ( ( targetRect.y >= pageTop ) && ( targetRect.bottom <= pageBottom ) ) {
				visiblePixle = targetRect.height;
			}

			if ( ( targetRect.y < pageTop ) && ( targetRect.bottom <= pageBottom ) ) {
				visiblePixle = targetRect.bottom - pageTop;
			}

			if ( ( targetRect.y >= pageTop ) && ( targetRect.bottom > pageBottom ) ) {
				visiblePixle = pageBottom - targetRect.y;
			}

			if ( ( targetRect.y < pageTop ) && ( targetRect.bottom > pageBottom ) ) {
				visiblePixle = pageBottom - pageTop;
			}

			return Math.round( ( ( visiblePixle / this.windowHeight ) * 100 ) );

		};

		getVisibilityDistanceFromCenter( targetRect, ScrollPos = this.getScrollPos() ) {

			if ( !this.checkIsInView( targetRect, ScrollPos ) ) return 0;

			let pageTop = ScrollPos[ 0 ];
			let pageCenter = pageTop + ( this.windowHeight / 2 );

			let distFromCenter = 0;

			if ( ( targetRect.y < pageCenter ) && ( targetRect.bottom < pageCenter ) ) {
				distFromCenter = pageCenter - targetRect.bottom;
			}

			if ( ( targetRect.y >= pageCenter ) && ( targetRect.bottom > pageCenter ) ) {
				distFromCenter = targetRect.bottom - pageCenter;
			}

			if ( ( targetRect.y <= pageCenter ) && ( targetRect.bottom >= pageCenter ) ) {
				distFromCenter = 0;
			}

			return distFromCenter;

		};

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'localscroll-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {

	$( window ).on( 'elementor/frontend/init', () => {
		if ( typeof elementorFrontend === 'undefined' ) {
			return;
		}
		elementorFrontend.on( 'components:init', () => {
			elementorFrontend.utils.anchors.setSettings( 'selectors.targets', '.to-the-hell' );
		} );
	} );

	$( '[data-localscroll]' ).liquidLocalScroll();

	$( '.lqd-mobile-sec-nav .main-nav' ).liquidLocalScroll( {
		itemsSelector: '> li > a'
	} );

} );