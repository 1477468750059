( function ( $ ) {

	'use strict';

	const pluginName = 'liquidSubmenu';

	let defaults = {
		toggleType: "fade", // fade, slide
		handler: "mouse-in-out", // click, mouse-in-out
		animationSpeed: 200
	};

	class Plugin {

		constructor( element, options ) {

			this.element = element;
			this.$element = $( element );

			this.options = { ...defaults, ...options };

			this._defaults = defaults;
			this._name = pluginName;

			this.rects = [];
			this.isModernMobileNav = document.body.hasAttribute( 'data-mobile-nav-style' ) && document.body.getAttribute( 'data-mobile-nav-style' ) === 'modern';
			this.windowWidth = fastdom.measure( liquidWindowWidth )();
			this.itemsAreFullwidth = this.element.classList.contains( 'lqd-menu-items-block' );

			this.init();

		}

		init() {

			const { handler } = this.options;
			const $submenuParent = $( this.$element.find( '.menu-item-has-children, .page_item_has_children' ).get().reverse() );

			$submenuParent.each( async ( i, subParent ) => {

				const isMegamenu = subParent.classList.contains( 'megamenu' );
				const submenu = subParent.querySelector( '.nav-item-children, .children' );

				if ( !submenu ) return;

				if ( handler === 'click' ) {
					this.element.classList.add( 'lqd-submenu-toggle-click' );
				} else if ( handler === 'mouse-in-out' ) {
					this.element.classList.add( 'lqd-submenu-toggle-hover' );
				}

				if ( ( !liquidIsMobile() || !$( subParent ).is( ':hidden' ) ) && !this.itemsAreFullwidth ) {

					if ( !isMegamenu ) {

						await this.measure( i, submenu, subParent );

					} else {

						this.getMegamenuBackgroundLuminance( subParent );

					}

				} else {

					subParent.classList.add( 'position-applied' )

				}

				this.eventHandlers( i, submenu, subParent );

			} );

			return this;

		}

		async measure( i, submenu, subParent ) {

			await this.getRects( i, submenu, subParent );
			await this.positioning( i, submenu, subParent );

		}

		eventHandlers( i, submenu, submenuParent ) {

			const { handler } = this.options;
			const $toggleLink = $( submenuParent ).children( 'a' );
			const $mobileNavExpander = $( '.submenu-expander', $toggleLink );

			if ( $mobileNavExpander.length ) {

				$mobileNavExpander.off();

				// Mobile nav
				$mobileNavExpander.on( 'click', event => {

					event.preventDefault();
					event.stopPropagation();

					this.mobileNav.call( this, $( event.currentTarget ).closest( 'li' ) );

				} );

			}

			if ( handler === 'click' ) {

				$toggleLink.off();

				$toggleLink.on( 'click', this.handleToggle.bind( this, 'toggle' ) );

				$( document ).on( 'click', this.closeActiveSubmenu.bind( this ) );

				$( document ).keyup( event => {

					if ( event.keyCode == 27 ) {

						this.closeActiveSubmenu( event );

					}

				} );

			} else {

				$( submenuParent ).off();

				$( submenuParent ).on( 'mouseenter', this.handleToggle.bind( this, 'show' ) );
				$( submenuParent ).on( 'mouseleave', this.handleToggle.bind( this, 'hide' ) );

			}

			document.addEventListener( 'lqd-header-sticky-change', async () => {

				await this.measure( i, submenu, submenuParent );

			} );

			return this;

		}

		handleToggle( state, event ) {

			const { toggleType, handler } = this.options;
			const $link = $( event.currentTarget );
			const submenu = ( $link.is( 'a' ) ) ? $link.siblings( '.nav-item-children, .children' ) : $link.children( '.nav-item-children, .children' );
			const isMegamenu = $link.is( '.megamenu' ) ? true : false;
			const megamenuBg = isMegamenu && $link.attr( 'data-bg-color' );
			const megamenuScheme = isMegamenu && $link.attr( 'data-megamenu-bg-scheme' );
			const $elementorHeaderWidgets = liquidIsElementor && $liquidMainHeader.find( '> .elementor:not(.lqd-mobile-sec) > .elementor-section-wrap > .elementor-section, > .elementor:not(.lqd-mobile-sec) > .elementor-section' ).not( '.lqd-stickybar-wrap' ).find( '> .elementor-container > .elementor-column > .elementor-widget-wrap > [data-element_type="widget"]' );

			if ( submenu.length ) {

				event.preventDefault();

				submenu
					.closest( 'li' )
					.toggleClass( `is-active ${ handler === 'mouse-in-out' && 'is-hovered' }`, state === 'show' )
					.siblings()
					.removeClass( `is-active ${ handler === 'mouse-in-out' && 'is-hovered' }` );

				if ( toggleType === 'fade' && state === 'show' ) {
					this.fadeIn( submenu, isMegamenu, megamenuScheme, megamenuBg, $elementorHeaderWidgets );
				} else if ( toggleType === 'fade' && state === 'hide' ) {
					this.fadeOut( submenu, isMegamenu, megamenuScheme, megamenuBg, $elementorHeaderWidgets );
				}

				if ( toggleType === 'slide' && state === 'show' ) {
					this.slideDown( submenu, isMegamenu, megamenuScheme, megamenuBg, $elementorHeaderWidgets );
				} else if ( toggleType === 'slide' && state === 'hide' ) {
					this.slideUp( submenu, isMegamenu, megamenuScheme, megamenuBg, $elementorHeaderWidgets );
				}

				if ( toggleType === 'fade' && state === 'toggle' ) {
					this.fadeToggle( submenu, isMegamenu, megamenuScheme, megamenuBg, $elementorHeaderWidgets );
				}

				if ( toggleType === 'slide' && state === 'toggle' ) {
					this.slideToggle( submenu, isMegamenu, megamenuScheme, megamenuBg, $elementorHeaderWidgets );
				}

			}

		}

		fadeToggle( submenu, isMegamenu, megamenuScheme, megamenuBg, $elementorHeaderWidgets ) {

			if ( isMegamenu ) {
				if ( $elementorHeaderWidgets && megamenuScheme !== 'transparent' && !$liquidMainHeader.hasClass( 'is-stuck' ) ) {
					$elementorHeaderWidgets.removeClass( `lqd-active-row-dark lqd-active-row-light` );
					$elementorHeaderWidgets.addClass( `lqd-active-row-${ megamenuScheme }` );
				}
				$liquidMainHeader[ 0 ].style.setProperty( '--lqd-megamenu-background-color', megamenuBg );
				$liquidMainHeader.removeClass( 'megamenu-scheme-dark megamenu-scheme-light megamenu-scheme-transparent' );
				$liquidMainHeader.toggleClass( `megamenu-item-active megamenu-scheme-${ megamenuScheme }` );
			}

		}

		fadeIn( submenu, isMegamenu, megamenuScheme, megamenuBg, $elementorHeaderWidgets ) {

			if ( isMegamenu ) {
				if ( $elementorHeaderWidgets && megamenuScheme !== 'transparent' && !$liquidMainHeader.hasClass( 'is-stuck' ) ) {
					$elementorHeaderWidgets.removeClass( `lqd-active-row-dark lqd-active-row-light` );
					$elementorHeaderWidgets.addClass( `lqd-active-row-${ megamenuScheme }` );
				}
				$liquidMainHeader[ 0 ].style.setProperty( '--lqd-megamenu-background-color', megamenuBg );
				$liquidMainHeader.removeClass( 'megamenu-scheme-dark megamenu-scheme-light megamenu-scheme-transparent' );
				$liquidMainHeader.addClass( `megamenu-item-active megamenu-scheme-${ megamenuScheme }` );
			}

			if ( submenu.find( '[data-lqd-flickity]' ).length ) {
				submenu.find( '[data-lqd-flickity]' ).flickity( 'resize' );
			}

		}

		fadeOut( submenu, isMegamenu, megamenuScheme, megamenuBg, $elementorHeaderWidgets ) {

			if ( isMegamenu ) {
				if ( $elementorHeaderWidgets ) {
					$elementorHeaderWidgets.removeClass( `lqd-active-row-dark lqd-active-row-light` );
				}
				$liquidMainHeader.removeClass( 'megamenu-scheme-dark megamenu-scheme-light megamenu-scheme-transparent' );
				$liquidMainHeader.removeClass( 'megamenu-item-active' );
			}

		}

		slideToggle( submenu, isMegamenu, megamenuScheme, megamenuBg, $elementorHeaderWidgets ) {

			submenu.closest( 'li' ).siblings().find( '.nav-item-children, .children' ).stop().slideUp( this.options.animationSpeed );
			submenu.stop().slideToggle( this.options.animationSpeed );

			if ( isMegamenu ) {
				if ( $elementorHeaderWidgets && megamenuScheme !== 'transparent' && !$liquidMainHeader.hasClass( 'is-stuck' ) ) {
					$elementorHeaderWidgets.removeClass( `lqd-active-row-dark lqd-active-row-light` );
					$elementorHeaderWidgets.addClass( `lqd-active-row-${ megamenuScheme }` );
				}
				$liquidMainHeader[ 0 ].style.setProperty( '--lqd-megamenu-background-color', megamenuBg );
				$liquidMainHeader.removeClass( 'megamenu-scheme-dark megamenu-scheme-light megamenu-scheme-transparent' );
				$liquidMainHeader.toggleClass( `megamenu-item-active megamenu-scheme-${ megamenuScheme }` );
			}

		}

		slideDown( submenu, isMegamenu, megamenuScheme, megamenuBg, $elementorHeaderWidgets ) {

			submenu.closest( 'li' ).siblings().find( '.nav-item-children, .children' ).stop().slideUp( this.options.animationSpeed );
			submenu.stop().slideDown( this.options.animationSpeed );

			if ( isMegamenu ) {
				if ( $elementorHeaderWidgets && megamenuScheme !== 'transparent' && !$liquidMainHeader.hasClass( 'is-stuck' ) ) {
					$elementorHeaderWidgets.removeClass( `lqd-active-row-dark lqd-active-row-light` );
					$elementorHeaderWidgets.addClass( `lqd-active-row-${ megamenuScheme }` );
				}
				$liquidMainHeader[ 0 ].style.setProperty( '--lqd-megamenu-background-color', megamenuBg );
				$liquidMainHeader.removeClass( 'megamenu-scheme-dark megamenu-scheme-light megamenu-scheme-transparent' );
				$liquidMainHeader.addClass( `megamenu-item-active megamenu-scheme-${ megamenuScheme }` );
			}

		}

		slideUp( submenu, isMegamenu, megamenuScheme, megamenuBg, $elementorHeaderWidgets ) {

			submenu.stop().slideUp( this.options.animationSpeed );

			if ( isMegamenu ) {
				if ( $elementorHeaderWidgets ) {
					$elementorHeaderWidgets.removeClass( `lqd-active-row-dark lqd-active-row-light` );
				}
				$liquidMainHeader.removeClass( 'megamenu-scheme-dark megamenu-scheme-light megamenu-scheme-transparent' );
				$liquidMainHeader.removeClass( 'megamenu-item-active' );
			}

		}

		getMegamenuBackgroundLuminance( subParent ) {

			const megamenuRowsWrap = subParent.querySelector( '.lqd-megamenu-rows-wrap' );
			let backgroundColor;

			fastdom.measure( () => {
				const styles = getComputedStyle( megamenuRowsWrap );
				backgroundColor = tinycolor( styles.backgroundColor );
			} );

			fastdom.mutate( () => {

				subParent.setAttribute( 'data-bg-color', backgroundColor );

				if ( backgroundColor.getAlpha() === 0 ) {
					return subParent.setAttribute( 'data-megamenu-bg-scheme', 'transparent' )
				}
				if ( backgroundColor.isLight() ) {
					return subParent.setAttribute( 'data-megamenu-bg-scheme', 'light' )
				}
				if ( backgroundColor.isDark() ) {
					return subParent.setAttribute( 'data-megamenu-bg-scheme', 'dark' )
				}

			} );

		}

		closeActiveSubmenu( event ) {

			const { toggleType, animationSpeed } = this.options;

			// if Esc key pressed
			if ( event.keyCode ) {

				const mainNav = $( this.element );

				if ( toggleType == 'fade' ) {

					mainNav.find( '.active' ).removeClass( 'active' ).find( '.nav-item-children, .children' ).stop().fadeOut( animationSpeed );

				} else {

					mainNav.find( '.active' ).removeClass( 'active' ).find( '.nav-item-children, .children' ).stop().slideUp( animationSpeed );

				}

			} else { // else if it was clicked in the document

				const submenuParent = $( this );

				if ( !submenuParent.is( event.target ) && !submenuParent.has( event.target ).length ) {

					submenuParent.removeClass( 'active' );

					if ( toggleType == 'fade' ) {

						submenuParent.find( '.nav-item-children, .children' ).stop().fadeOut( animationSpeed );

					} else {

						submenuParent.find( '.nav-item-children, .children' ).stop().slideUp( animationSpeed );

					}

				}

			}

		}

		mobileNav( submenuParent ) {

			const $submenuParent = $( submenuParent );
			const $submenu = $submenuParent.children( '.nav-item-children, .children' );
			const $navbarInner = $submenuParent.closest( '.navbar-collapse-inner' );
			const submenuParentWasActive = $submenuParent.hasClass( 'is-active' );

			$submenuParent.toggleClass( 'is-active' );
			$submenuParent.siblings().removeClass( 'is-active' ).find( '.nav-item-children, .children' ).stop().slideUp( 200 );

			$submenu.stop().slideToggle( 300, () => {

				if ( this.isModernMobileNav && !submenuParentWasActive && $navbarInner.length ) {

					$navbarInner.animate( {
						scrollTop: $navbarInner.scrollTop() + ( $submenuParent.offset().top - $navbarInner.offset().top )
					} );

				}

			} );

		}

		async getRects( i, submenu, submenuParent ) {

			this.rects[ i ] = {
				submenuRect: {},
				subParentRect: {}
			}

			return fastdomPromised.measure( () => {

				return new Promise( resolve => {
					new IntersectionObserver( ( [ entry ], observer ) => {
						const { boundingClientRect } = entry;
						observer.disconnect();
						resolve( boundingClientRect );
					} ).observe( submenu )
				} );

			} ).then( submenuRect => {

				this.rects[ i ].submenuRect = submenuRect;

				return new Promise( resolve => {
					new IntersectionObserver( ( [ entry ], observer ) => {
						const { boundingClientRect } = entry;
						observer.disconnect();
						resolve( boundingClientRect );
					} ).observe( submenuParent );
				} );

			} ).then( subParentRect => {

				this.rects[ i ].subParentRect = subParentRect;

			} )

		}

		positioning( i, submenu, submenuParent ) {

			return fastdomPromised.mutate( () => {

				const submenuRect = this.rects[ i ].submenuRect;
				const subParentRect = this.rects[ i ].subParentRect;

				if ( submenuRect.left + submenuRect.width >= this.windowWidth ) {
					submenu.classList.add( 'to-left' );
				}

				submenuParent.style.setProperty( '--item-height', `${ subParentRect.height }px` );

				submenuParent.classList.add( 'position-applied' );

			} );

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'submenu-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {
	$( '.main-nav, .lqd-custom-menu' ).liquidSubmenu();
} );